import React, { useEffect, useState } from "react";
import AccountInfo from "../../components/accountInfo";
import UsersDataGridTable from "../../components/users-data-grid";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { fetchUsersFromOrganization } from "../../functions/users";
import { logout } from "../../firebase";
import EnableMFAModal from "../../components/enableMFA"; //not implemented currently
import TextButton from "../../components/textButton";
import CustomLogo from "../../components/logo";
import OrganizationPicker from "../../components/orgPicker";
import { useOutletContext } from "react-router-dom";

const AccountInfoScreen = () => {

  const layoutProps = useOutletContext();

  const [user, waiting] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  //not currently used
  const [mfaModalOpen, setMfaModalOpen] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [suppressNavigation, setSuppressNavigation] = useState(false);

  const handleOpenMfaModal = () => {
    setMfaModalOpen(true);
  };
  const handleCloseMfaModal = () => {
    setMfaModalOpen(false);
  };
  //

  const getUsers = async () => {
    setLoading(true);
    let usersData;
    if (layoutProps.selectedOrgID !== "") {
      usersData = await fetchUsersFromOrganization(layoutProps.selectedOrgID);
    } else {
      usersData = await fetchUsersFromOrganization(layoutProps.orgID);
    }
    setUsers(usersData);
    setLoading(false);
  };

  useEffect(() => {
    if (layoutProps.orgID) {
      getUsers();
    };
  }, [layoutProps.orgID, layoutProps.selectedOrgID]);

  const handleLogout = () => {
    if (user) {
      logout();
      navigate("/login");
    };
  };

  return (
    <div style={{
      display: "flex",
      backgroundColor: '#1F1F1F',
      height: 'auto',
      minHeight: '100vh',
      minWidth: '1300px',
      justifyContent: 'center',
      marginTop: '86px'
    }}>
      <div style={{
        marginTop: '30px',
        display: "flex",
        flexDirection: "column",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: 'space-between'
        }}>
          <div
            className="headlineLarge"
            style={{
              width: "350px",
              height: "auto",
              display: "block",
              alignSelf: 'start',
              marginTop: '10px',
              color: '#FFFFFF',
            }}
          >
            Account management
          </div>
          {(layoutProps.isOverviewAccount === 'true' || layoutProps.orgID == 'LZqI3R6MInUuwtgtROPK') &&
            <OrganizationPicker
              layoutProps={layoutProps}
              label={true}
            />
          }
        </div>
        <div style={{
          marginTop: '30px',
          display: "flex",
          flexDirection: "row",
          marginBottom: '30px'
        }}>
          <div style={{
            width: "560px",
            borderRadius: '24px',
            minHeight: '824px',
            backgroundColor: '#FAFAFA',
            maxHeight: '824px',
            overflow: 'scroll',
            marginRight: '16px',
          }}>
            <div style={{
              marginTop: '40px',
              marginLeft: '40px',
            }}>
              <div
                className='titleLarge'
                style={{ marginBottom: '12px' }}
              >
                Details
              </div>
              <AccountInfo
                orgID={layoutProps.selectedOrgID !== "" ? layoutProps.selectedOrgID : layoutProps.orgID}
                role={layoutProps.role}
              />
              <div style={{
                marginTop: '32px',
                width: '480px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '50px'
              }}>
                <TextButton
                  label={'Sign Out'}
                  underlined={true}
                  onPress={handleLogout}
                />
              </div>
            </div>
          </div>
          <div style={{
            width: "560px",
            borderRadius: '24px',
            minHeight: '824px',
            maxHeight: '824px',
            backgroundColor: '#FAFAFA',
            overflow: 'hidden'
          }}>
            <div style={{
              marginTop: '40px',
              marginLeft: '40px',
            }}>
              <div
                className='titleLarge'
                style={{ marginBottom: '24px' }}
              >
                Organization Members
              </div>
              <UsersDataGridTable
                user={user}
                data={users}
                loading={loading}
                getUsers={getUsers}
                orgID={layoutProps.selectedOrgID !== "" ? layoutProps.selectedOrgID : layoutProps.orgID}
                role={layoutProps.role}
                setSuppressNavigation={setSuppressNavigation}
              />
            </div>
          </div>
        </div>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}>
          <CustomLogo name="horizontal-white" />
        </div>
      </div>
    </div>
  );
};

export default AccountInfoScreen;
