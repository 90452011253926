import React from "react"
import { neutral300, primary500 } from "../../lib/rebrandColors/colors";

export default function SavingsGraphicLegend() {
    const legendTextStyle = {
        fontSize: "12px",
        fontFamily: "IBM Plex Sans",
        alignSelf: 'center',
        width: '175px',
        textAlign: 'center'
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginBottom: '10px'
        }}>
            {/* without block */}
            <div style={{
                display: 'flex',
                marginRight: '10px',
            }}>
                <div style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '7.5px',
                    backgroundColor: neutral300,
                    alignSelf: 'center',
                    marginRight: '5px'
                }}>
                </div>
                <div style={legendTextStyle}>
                    Projected Cost with Schedule
                </div>
            </div>
            {/* with block */}
            <div style={{
                display: 'flex',
            }}>
                <div style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '7.5px',
                    backgroundColor: primary500,
                    alignSelf: 'center',
                    marginRight: '5px'
                }}>
                </div>
                <div style={{
                    fontSize: "12px",
                    fontFamily: "IBM Plex Sans",
                    alignSelf: 'center',
                    width: '100px',
                    textAlign: 'center'
                }}>
                    Projected Savings
                </div>
            </div>
        </div>
    );
};