import React, { useState, useEffect } from "react";
import "../../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { orgSelectPress } from "../../functions/organizations";
import DropDown from "../../components/dropDown"

//must detect in home that current user is memeber of ReVert Org
export default function OrganizationPicker({
  layoutProps,
  label = false
}) {
  //useEffect to get all of the possible organizations
  //useState variable to hold the list of orgs
  //drop down box with each org as an option
  //sets state of OrgID to be sent into outletList with selected option
  const [orgEdit, setOrgEdit] = useState(false);
  const [pickedOrgID, setPickedOrgID] = useState(layoutProps.selectedOrgID);
  const [pickedOrgName, setPickedOrgName] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (!layoutProps.selectedOrgID && layoutProps.allOrgs.length > 1) {
      orgSelectPress(
        pickedOrgID,
        layoutProps.allOrgs[0].uid,
        setPickedOrgID,
        setOrgEdit,
        layoutProps.setSelectedOrgID,
        layoutProps.setTimezone,
        layoutProps.setRate
      );
      setPickedOrgName(Object.values(layoutProps.allOrgs).find(item => item.uid === layoutProps.allOrgs[0].uid).name);
    }else if(layoutProps.allOrgs.length > 1){
      setPickedOrgName(Object.values(layoutProps.allOrgs).find(item => item.uid === pickedOrgID).name);
    }
  }, [layoutProps.allOrgs]);

  const selectOrgPress = (orgName) => {
    //note: function that updates selected orgID
    setPickedOrgName(orgName)
    orgSelectPress(
      pickedOrgID,
      Object.values(layoutProps.allOrgs).find(item => item.name === orgName).uid,
      setPickedOrgID,
      setOrgEdit,
      layoutProps.setSelectedOrgID,
      layoutProps.setTimezone,
      layoutProps.setRate
    );
    if (layoutProps.setSelectedApplianceType) layoutProps.setSelectedApplianceType("");
  };

  const orgNames = Object.values(layoutProps.allOrgs).map(item => item.name);
  orgNames.sort((a, b) => {
    if (a < b) {
      return -1;
    };
    if (a > b) {
      return 1;
    };
  });

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: 'center',
    }}>
      <DropDown label={label && 'Organization'} list={orgNames} value={pickedOrgName} onSelect={selectOrgPress} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen}/>
    </div>
  );
}