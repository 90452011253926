import React from "react";
import { CircularProgress } from "@mui/material";
import { aggregateByMonthCost, aggregateByMonthEnergy } from "../../functions/wattHours";

export default function SavingsDisplay({
    layoutProps,
    outletID
}) {

    //Next couple of lines account for single outlet displays instead of aggregated outlet displays 
    let savings = layoutProps.savings
    if(outletID){
        savings = savings.filter(
            (item) => item.outletID === outletID
        );
    }

    if (layoutProps.savingsLoading === false) {
        const savingsObject = savings.reduce((acc, item) => {
            const { date, outletID, co2eSavings, costSavings, energySavings } = item;
            if (!acc[date]) {
                acc[date] = [];
            };
            acc[date].push({
                outletID,
                savings: {
                    co2eSavings,
                    costSavings,
                    energySavings,
                },
            });
            return acc;
        }, {});

        const dollarSavingsData = aggregateByMonthCost(savingsObject);
        const energySavingsData = aggregateByMonthEnergy(savingsObject);
        const totalCostSavings = dollarSavingsData.reduce((acc, item) => acc + item.totalCostSavings, 0);
        let totalEnergySavings = energySavingsData.reduce((acc, item) => acc + item.totalCostSavings, 0) / 1000;
        let energyUnits = 'kWhs';
        if (totalEnergySavings >= 1000) {
            totalEnergySavings = totalEnergySavings / 1000;
            energyUnits = 'MWhs'
        };


        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                //marginTop: '45px',
                justifyContent: 'space-evenly',
                width: '100%'
            }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        alignItems: 'center',
                    }}
                >
                    <div
                        className="labelMedium"
                    >
                        Lifetime Savings
                    </div>
                    <div
                        className="titleLarge"
                        style={{
                            color: '#2789FF',
                        }}
                    >
                        ${totalCostSavings.toFixed(2)}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        alignItems: 'center',
                    }}
                >
                    <div
                        className="labelMedium"
                    >
                        Energy Saved
                    </div>
                    <div
                        className="titleLarge"
                        style={{
                            color: '#2789FF',
                        }}
                    >
                        {totalEnergySavings.toFixed(2)} {energyUnits}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px'
            }}>
                <CircularProgress />
            </div>
        );
    };
};