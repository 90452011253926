import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import CustomLogo from "../logo";
import IconButton from "../iconButton";
import TabBar from "../tabBar";
import SideModal from "../sideModal";
import NotificationsModal from "../notificationsModal";

export default function Header({
  orgID,
  selectedOrgID,
  setSelectedOrgID,
  width,
}) {

  const [user, waiting] = useAuthState(auth);
  const [activePage, setActivePage] = useState(
    window.location.pathname.split("/")[1] || "dashboard"
  );
  const [isSideOpen, setSideOpen] = useState(false);
  const openModal = () => {
    setSideOpen(true);
  };
  const closeModal = () => {
    setSideOpen(false);
  };
  const navigate = useNavigate();

  const toggleMenu = (pageName) => {
    setActivePage(pageName.toLowerCase());
    if (orgID === 'LZqI3R6MInUuwtgtROPK') {
      setActiveIndexAdmin(
        adminSections.findIndex(
          (section) => section.toLowerCase() === pageName.toLowerCase()
        )
      );
    } else {
      setActiveIndex(
        sections.findIndex(
          (section) => section.toLowerCase() === pageName.toLowerCase()
        )
      );
    };
    navigate("/" + (pageName === "Dashboard" ? "" : pageName.toLowerCase()), {
      state: { orgID },
    });
  };
  useEffect(() => {
    if (waiting) return;
    if (!user) navigate("/login");
  }, [navigate, user, waiting]);

  const adminSections = [
    "Dashboard",
    "Appliances",
    "Schedules",
    "Outlets",
    "Inventory",
    "Organizations",
    "Test",
  ];
  const sections = [
    "Dashboard",
    "Appliances",
    "Schedules"
  ];
  const [activeIndexAdmin, setActiveIndexAdmin] = useState(
    window.location.pathname.split("/")[1] === '' ?
      0 :
      adminSections.findIndex(
        (section) => section.toLowerCase() === window.location.pathname.split("/")[1]
      )
  );
  const [activeIndex, setActiveIndex] = useState(
    window.location.pathname.split("/")[1] === '' ?
      0 :
      sections.findIndex(
        (section) => section.toLowerCase() === window.location.pathname.split("/")[1]
      )
  );

  const dashboardOnPress = () => toggleMenu('Dashboard');
  const appliancesOnPress = () => toggleMenu('Appliances');
  const schedulesOnPress = () => toggleMenu('Schedules');
  const outletsOnPress = () => toggleMenu('Outlets');
  const inventoryOnPress = () => toggleMenu('Inventory');
  const organizationsOnPress = () => toggleMenu('Organizations');
  const testOnPress = () => toggleMenu('Test');
  const adminOnPressArr = [
    dashboardOnPress,
    appliancesOnPress,
    schedulesOnPress,
    outletsOnPress,
    inventoryOnPress,
    organizationsOnPress,
    testOnPress,
  ];
  const onPressArr = [
    dashboardOnPress,
    appliancesOnPress,
    schedulesOnPress,
  ];
  const accountOnPress = () => {
    setActiveIndex(-1);
    setActiveIndexAdmin(-1);
    navigate("/account");
  };
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] = useState(false);
  const toggleNotificationsModal = () => setIsNotificationsModalOpen(!isNotificationsModalOpen);
  const closeNotificationsModal = () => setIsNotificationsModalOpen(false);

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '50px',
          backgroundColor: '#2D2D2D',
          width: width,
          height: '86px',
          justifyContent: 'space-between',
          top: 0,
          left: 0,
          zIndex: 5,
        }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: width > 1200 ? '10%' : '3%'
        }}>
          <div
            onClick={() => toggleMenu("Dashboard")}
            style={{
              // marginLeft: '5%', //make dependent on width
              cursor: 'pointer',
              marginRight: '40px'
            }}
          >
            <CustomLogo
              name='horizontal-negative'
              width={'160px'}
            />
          </div>
          {(
            orgID == 'LZqI3R6MInUuwtgtROPK' &&
            setSelectedOrgID
          ) ? width > 1300 ?
            (orgID === 'LZqI3R6MInUuwtgtROPK' ?
              <TabBar
                labelArr={adminSections}
                onPressArr={adminOnPressArr}
                size="large"
                activeIndex={activeIndexAdmin}
                fontSize={'13px'} //update with width maybe
              />
              :
              <TabBar
                labelArr={sections}
                onPressArr={onPressArr}
                size="large"
                activeIndex={activeIndex}
                fontSize={'13px'}
              />
            ) : null
            : width > 768 ?
              (orgID === 'LZqI3R6MInUuwtgtROPK' ?
                <TabBar
                  labelArr={adminSections}
                  onPressArr={adminOnPressArr}
                  size="large"
                  activeIndex={activeIndexAdmin}
                  fontSize={'13px'} //update with width maybe
                />
                :
                <TabBar
                  labelArr={sections}
                  onPressArr={onPressArr}
                  size="large"
                  activeIndex={activeIndex}
                  fontSize={'13px'}
                />
              ) : null
          }
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '3%'
        }}>
          {(
            orgID == 'LZqI3R6MInUuwtgtROPK' &&
            setSelectedOrgID
          ) ? width > 1300 ?
            <div style={{
              display: 'flex'
            }}>
              <div style={{
                marginRight: '10px'
              }}>
                <IconButton
                  iconName={'notification'}
                  disabled={false}
                  buttonType="tonal"
                  onClick={toggleNotificationsModal}
                />
              </div>
              <IconButton
                iconName={'user-icon'}
                disabled={false}
                buttonType={activeIndexAdmin === -1 ? "outlined" : 'tonal'}
                onClick={accountOnPress}
              />
            </div>
            :
            <IconButton
              iconName={'menu-1'}
              buttonType="standard"
              onClick={openModal}
            />
            :
            width > 768 ?
              <div style={{
                display: 'flex'
              }}>
                <div style={{
                  marginRight: '10px'
                }}>
                  <IconButton
                    iconName={'notification'}
                    disabled={false}
                    buttonType="tonal"
                    onClick={toggleNotificationsModal}
                  />
                </div>
                <IconButton
                  iconName={'user-icon'}
                  buttonType={activeIndex === -1 ? "outlined" : 'tonal'}
                  onClick={accountOnPress}
                />
              </div>
              :
              <IconButton
                iconName={'menu-1'}
                buttonType="standard"
                onClick={openModal}
              />
          }
        </div>
        <SideModal
          isOpen={isSideOpen}
          onClose={closeModal}
          buttonArr={orgID == 'LZqI3R6MInUuwtgtROPK' ? adminSections : sections}
          onPressArr={orgID == 'LZqI3R6MInUuwtgtROPK' ? adminOnPressArr : onPressArr}
          activeIndex={orgID == 'LZqI3R6MInUuwtgtROPK' ? activeIndexAdmin : activeIndex}
          fontSize={'12px'}
          setActiveIndex={setActiveIndex}
          setActiveIndexAdmin={setActiveIndexAdmin}
        />
      </div>
      <NotificationsModal orgID={orgID} selectedOrgID={selectedOrgID} open={isNotificationsModalOpen} handleClose={closeNotificationsModal}/>
    </div >
  );
};