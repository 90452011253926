import React, { useState } from "react";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { Box, Modal, Typography } from "@mui/material";
import ApplianceDetailsCard from "../applianceDetailsCard";
import CustomButton from "../customButton";
import { primary500, white } from "../../lib/rebrandColors/colors";
import { updateOutletDetails } from "../../functions/outlets";

const ApplianceEditScreen = ({
  outlet,
  prodTypes,
  orgData,
  wattHours,
  averageUsage,
  savings,
  open,
  handleClose,
  setCurrentOutlet
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;
  const layoutProps = useOutletContext();

  const [editedOutlet, setEditedOutlet] = useState({ ...outlet });
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (field, value) => {
    setEditedOutlet((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      // Call the updateOutlet function
      await updateOutletDetails(editedOutlet);
      setCurrentOutlet(editedOutlet);
      layoutProps.setUpdate(!layoutProps.update);
      console.log("[Appliance Edit Details] Outlet updated successfully");
      // navigate("/appliances/details", { state: { outlet: editedOutlet, prodTypes, orgData,  wattHours, averageUsage, savings  } });
      handleClose();
    } catch (error) {
      console.error("[Appliance Edit Details] Failed to update outlet:", error);
    } finally {
      setLoading(false);
    };
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <div style={{
        position: "absolute",
        top: "5%",
        left: "5%",
        width: '90vW',
        height: '90vH',
        overflowY: 'scroll',
        borderRadius: "24px",
        bgcolor: '#FAFAFA',
      }}>
        <div
          style={{
            backgroundColor: "#1F1F1F",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "15px",
            paddingTop: "25px",
          }}
        >
          {/* Page Header */}
          <Box sx={{
            width: "100%",
            maxWidth: "800px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}>
            <Typography sx={{
              color: white,
              fontSize: "24px",
              fontWeight: "bold",
            }}>
              Edit Appliance
            </Typography>
            {/* Action Buttons */}
            <Box sx={{ display: "flex", gap: "16px" }}>
              <CustomButton
                label="Cancel"
                filled={false}
                size="small"
                onClick={handleCancel}
                disabled={loading}
                colored={true}
              />
              <CustomButton
                label={loading ? "Saving..." : "Save"}
                filled={true}
                size="small"
                onClick={handleSave}
                disabled={loading}
                colored={true}
              />
            </Box>
          </Box>

          {/* ApplianceDetailsCard in Editing Mode */}
          <ApplianceDetailsCard
            outlet={editedOutlet}
            editing={true}
            onFieldChange={handleFieldChange}
            orgData={orgData}
            prodTypes={prodTypes}
            wattHours={wattHours}
            averageUsage={averageUsage}
            savings={savings}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ApplianceEditScreen;