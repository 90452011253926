import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
} from "@mui/material";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import DeleteIcon from "@mui/icons-material/Delete";
import { green } from "../../lib/colors";
import { updateOrganizationStatus, deleteOrgData, returnOutletsToInventory } from "../../functions/organizations";
import { deleteOutlets } from "../../functions/outlets";

const renderCellContent = (params) => {
  const hasNoData = params.value === "No data";
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {params.value || "No data"}
    </div>
  );
};

const ActionCell = ({ id, onStatusChange, onDelete }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        gap: "10px",
      }}
    >
      <Tooltip title="Change status">
        <LocalPoliceIcon
          style={{ cursor: "pointer", color: green }}
          onClick={() => onStatusChange(id)}
        />
      </Tooltip>
      <Tooltip title="Delete from list">
        <DeleteIcon
          style={{ cursor: "pointer", color: "orange", marginRight: "15px" }}
          onClick={() => onDelete(id)}
        />
      </Tooltip>
    </Box>
  );
};

const OrganizationsDataGridTable = ({ data, loading, getOrganizations }) => {
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [outletReturnDialogOpen, setOutletReturnDialogOpen] = useState(false);
  const [currentOrgId, setCurrentOrgId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [outletReturn, setOutletReturn] = useState(false);

  const handleStatusChangeClick = (id) => {
    setCurrentOrgId(id);
    setStatusDialogOpen(true);
  };

  const handleStatusChangeConfirm = async () => {
    setStatusDialogOpen(false);
    try {
      await updateOrganizationStatus(currentOrgId, selectedStatus);
      await getOrganizations(); // Refresh organization data after update
    } catch (error) {
      console.error("Error updating organization status:", error);
    };
  };

  const handleDeleteClick = (id) => {
    setCurrentOrgId(id);
    // setDeleteDialogOpen(true);
    setOutletReturnDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    console.log(`Deleting Org: ${currentOrgId}`);
    handleOutletReturnConfirm();
    // function that deletes org and org data
    deleteOrgData(currentOrgId);
    setDeleteDialogOpen(false);
  };

  // const handleOutletReturnClick = () => {
  //   setOutletReturnDialogOpen(true);
  // };
  const handleOutletReturnConfirm = () => {
    if (outletReturn === true) {
      returnOutletsToInventory(currentOrgId);
    } else {
      deleteOutlets(currentOrgId);
    };
    setOutletReturn(false);
    setOutletReturnDialogOpen(false);
  };

  const rows = data.map((org) => ({
    id: org.uid,
    name: org.name,
    status: org.status,
  }));

  const columns = [
    {
      field: "name",
      headerName: "Organization Name",
      flex: 1,
      renderCell: renderCellContent,
    },
    {
      field: "id",
      headerName: "Organization ID",
      flex: 1,
      renderCell: renderCellContent,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: renderCellContent,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => (
        <ActionCell
          id={params.id}
          onStatusChange={handleStatusChangeClick}
          onDelete={handleDeleteClick}
        />
      ),
      headerAlign: "center",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 10,
        marginTop: 2,
      }}
    >
      <Box sx={{ width: "50%", height: "80vh" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          disableExtendRowFullWidth
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
        />
      </Box>
      {/* status */}
      <Dialog
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
      >
        <DialogTitle>Change Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the new status for the organization.
          </DialogContentText>
          <Select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            fullWidth
          >
            <MenuItem value="active">Active</MenuItem>
            {/* We can add more statuses here in the future if needed */}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleStatusChangeConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* outletReturn */}
      <Dialog
        open={outletReturnDialogOpen}
        onClose={() => {
          setOutletReturnDialogOpen(false)
          setDeleteDialogOpen(true)
        }}
      >
        <DialogTitle>Return outlets to inventory?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to re-add this organization's outlets to inventory for recirculation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOutletReturnDialogOpen(false)
            setDeleteDialogOpen(true)
            setOutletReturn(false)
          }}>
            No
          </Button>
          <Button
            onClick={() => {
              setOutletReturn(true)
              setOutletReturnDialogOpen(false)
              setDeleteDialogOpen(true)
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete org */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm organization deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this organization? Their data and users will not be recoverable.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrganizationsDataGridTable;
