import React, { useState, useEffect } from "react";
import {
    Modal,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    Box,
    Checkbox,
    ListItemText,
    CircularProgress,
    Chip,
    Typography,
} from "@mui/material";
import '../../../App.css';
import {
    neutral800,
    primary500,
    primary600,
    primary700,
    white,
    neutral700,
    neutral500,
    primary580,
    black,
    grey600,
    neutral400,
    elevationLight1,
    neutral300,
    orange300,
} from "../../../lib/rebrandColors/colors";
import Dropdown from "../../dropDown";
import TextField from "../../textField";
import CustomButton from "../../customButton";
import IconButton from "../../iconButton";
import styles from '../styles';
import { handleCreateSchedule, handleEditSubmit, handleScheduleDeleteSubmit } from "../../../functions/schedules";
import CustomIcon from "../../icon"
import { calculateProjectedCostPerOutlet, calculateProjectedSavings } from "../../../functions/savings"
import ScheduleSavingsGraphic from "../../scheduleSavingsGraphic";
import SavingsGraphicLegend from "../../savingsGraphicLegend";

const minutes_arr = Array.from({ length: 61 }, (_, i) => String(i).padStart(2, '0'));
const hours_arr = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));

export default function ScheduleForm({
    edit,
    open,
    handleClose,
    outlets,
    child_org_data,
    schedules,
    schedule = null,
    setClickedSchedule,
    selectedOrgID,
    orgID,
    setSchedules,
    refresh,
    setRefresh
}) {
    const [selectedSchedule, setSelectedSchedule] = useState(schedule);
    const [projectedSavings, setProjectedSavings] = useState(0); // State to store projected savings
    const [projectedCost, setProjectedCost] = useState(0); // State to store projected cost
    const currentSchedule = schedule;
    const [missionId, setMissionId] = useState('');
    const [onHour, setOnHour] = useState('12');
    const [onMinute, setOnMinute] = useState('00');
    const [offHour, setOffHour] = useState('12');
    const [offMinute, setOffMinute] = useState('00');
    const [selectedOutlets, setSelectedOutlets] = useState([]);
    const [repeat, setRepeat] = useState([]);
    const [onMinDropdownOpen, setOnMinDropdownOpen] = useState(false);
    const onMinDropdownClick = (item) => {
        setOnMinute(item);
    };
    const [onHourDropdownOpen, setOnHourDropdownOpen] = useState(false);
    const onHourDropdownClick = (item) => {
        setOnHour(item);
        setScheduleError(false);
    };
    const [offMinDropdownOpen, setOffMinDropdownOpen] = useState(false);
    const offMinDropdownClick = (item) => {
        setOffMinute(item);
        setScheduleError(false);
    };
    const [offHourDropdownOpen, setOffHourDropdownOpen] = useState(false);
    const offHourDropdownClick = (item) => {
        setOffHour(item);
        setScheduleError(false);
    };

    const [offAMPMDropdownValue, setOffAMPMDropdownValue] = useState('PM');
    const [offAMPMDropdownOpen, setOffAMPMDropdownOpen] = useState(false);
    const offAMPMDropdownClick = (item) => {
        setOffAMPMDropdownValue(item);
        setScheduleError(false);
    };

    const AMPMDropdownArr = ['AM', 'PM'];

    const [onAMPMDropdownValue, setOnAMPMDropdownValue] = useState('AM');
    const [onAMPMDropdownOpen, setOnAMPMDropdownOpen] = useState(false);
    const onAMPMDropdownClick = (item) => {
        setOnAMPMDropdownValue(item);
        setScheduleError(false);
    };

    const weekArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const toggleDay = (day) => {
        setScheduleError(false);
        setRepeat((prevRepeat) =>
            prevRepeat.includes(day)
                ? prevRepeat.filter((d) => d !== day) // Remove day if it exists
                : [...prevRepeat, day] // Add day if it doesn't exist
        );
    };

    const [productTypesArr, setProductTypesArr] = useState([]);
    const [scheduleProdType, setScheduleProdType] = useState([]);
    const [scheduleError, setScheduleError] = useState(false);
    // product types for displaying existing schedules to edit 
    useEffect(() => {
        const newProductTypesArr = schedules.map((schedule) => {
            const productTypes = schedule?.outletsSelected
                ?.map(outlet => outlets.find(obj => obj.id === outlet)?.productType)
                .filter(Boolean);
            return [...new Set(productTypes)];
        });
        setProductTypesArr(newProductTypesArr);
        // sets the product types contained within the schedule
        let productTypes = [];
        if (schedule) {
            productTypes = [...new Set(schedule.outletsSelected
                .map(outlet => outlets.find(obj => obj.id === outlet)?.productType)
                .filter(Boolean))];
            setScheduleProdType(productTypes);
        };
    }, [schedules, outlets, schedule]);

    //if editing a schedule set all the schedule values
    useEffect(() => {
        if (edit) {
            if (schedule !== null) {
                setSelectedSchedule(schedule);
            };
            if (selectedSchedule !== null && selectedSchedule !== undefined) {
                if ('missionId' in selectedSchedule) {
                    setMissionId(
                        selectedSchedule.missionId !== '' ?
                            selectedSchedule.missionId :
                            child_org_data.find(org => org.uid === selectedSchedule.orgID)?.name
                    );
                };
                if ('onHour' in selectedSchedule) {
                    if (selectedSchedule.onHour == 0) {
                        setOnHour('12');
                    } else {
                        setOnHour(selectedSchedule.onHour > 12 ? selectedSchedule.onHour - 12 : selectedSchedule.onHour);
                    };
                    setOnMinute(selectedSchedule.onMinute.toString().length !== 2 ?
                        `0${selectedSchedule.onMinute}` : selectedSchedule.onMinute);
                    setOffHour(selectedSchedule.offHour > 12 ? selectedSchedule.offHour - 12 : selectedSchedule.offHour);
                    setOffMinute(selectedSchedule.offMinute.toString().length !== 2 ?
                        `0${selectedSchedule.offMinute}` : selectedSchedule.offMinute);
                    setOffAMPMDropdownValue(selectedSchedule.offHour >= 12 ? 'PM' : 'AM');
                    setOnAMPMDropdownValue(selectedSchedule.onHour >= 12 ? 'PM' : 'AM');
                    setSelectedOutlets(outlets.filter((obj) => selectedSchedule.outletsSelected.includes(obj.id)));
                };
                if ('repeat' in selectedSchedule) {
                    setRepeat(selectedSchedule.repeat);
                };
            };
        };
    }, [selectedSchedule, schedule]);

    // Fetch projected savings when a schedule is selected or updated
    useEffect(() => {
        const fetchProjectedSavings = async () => {
            if (selectedOutlets.length > 0) {
                let onHourHelp = Number(onHour)
                if (onAMPMDropdownValue == 'PM') {
                    onHourHelp = onHourHelp + 12
                } else if (onHourHelp == 12) {
                    onHourHelp = 0;
                };
                let offHourHelp = Number(offHour)
                if (offAMPMDropdownValue == 'PM' && offHourHelp != 12) {
                    offHourHelp = offHourHelp + 12
                } else if (offAMPMDropdownValue == 'AM' && offHourHelp == 12) {
                    offHourHelp = 0;
                };

                // Use Promise.all to fetch savings concurrently
                const savingsArray = await Promise.all(
                    selectedOutlets.map((outlet) => {
                        const savings = calculateProjectedSavings(
                            outlet.id, // Outlet ID
                            onHourHelp,
                            onMinute,
                            offHourHelp,
                            offMinute,
                            repeat
                        );
                        //console.log("Savings for outlet", outlet.id, ":", savings);
                        return savings;
                    })
                );
                const costPerOutlet = await Promise.all(
                    selectedOutlets.map((outlet) => {
                        const cost = calculateProjectedCostPerOutlet(
                            outlet.id, // Outlet ID
                            onHourHelp,
                            onMinute,
                            offHourHelp,
                            offMinute,
                            repeat
                        );
                        return cost;
                    })
                );
                // Calculate total savings
                const totalSavings = savingsArray.reduce((sum, savings) => sum + savings, 0);
                setProjectedSavings(totalSavings);
                // calculate total cost
                const totalCost = costPerOutlet.reduce((sum, cost) => sum + cost, 0);
                setProjectedCost(totalCost);
            };
        };

        fetchProjectedSavings();
    }, [selectedOutlets, repeat, onHour, offHour, onAMPMDropdownValue, offAMPMDropdownValue]);

    const onSubmit = async () => {
        let newOnHour = Number(onHour);
        if (newOnHour === 12 && onAMPMDropdownValue === 'AM') {
            newOnHour = 0;
        } else if (newOnHour < 12 && onAMPMDropdownValue === 'PM') {
            newOnHour += 12;
        };
        const newOnMinute = Number(onMinute);

        let newOffHour = Number(offHour);
        if (newOffHour === 12 && offAMPMDropdownValue === 'AM') {
            newOffHour = 0;
        } else if (newOffHour < 12 && offAMPMDropdownValue === 'PM') {
            newOffHour += 12;
        };
        const newOffMinute = Number(offMinute);

        const newRepeat = repeat.sort((a, b) => weekArr.indexOf(a) - weekArr.indexOf(b));

        // Transforming the array to just contain the outlet ids
        const newOutletsSelected = selectedOutlets.map(obj => obj.id);
        let eventData = {};

        let create;
        if (schedule !== null) {
            eventData = {
                id: selectedSchedule.id,
                missionId,
                offHour: newOffHour,
                offMinute: newOffMinute,
                onHour: newOnHour,
                onMinute: newOnMinute,
                outletsSelected: newOutletsSelected,
                repeat: newRepeat,
            };
            create = await handleEditSubmit(eventData, refresh, setRefresh, schedules, setScheduleError, setSchedules);
        } else {
            eventData = {
                missionId,
                offHour: newOffHour,
                offMinute: newOffMinute,
                onHour: newOnHour,
                onMinute: newOnMinute,
                outletsSelected: newOutletsSelected,
                repeat: newRepeat,
            };
            create = await handleCreateSchedule(eventData, selectedOrgID, orgID, setSchedules, schedules, setScheduleError, refresh, setRefresh);
        };

        if (create) {
            setSelectedSchedule(null);
            setClickedSchedule(null);
            handleClose();
        };
    };

    const onDeleteSubmit = () => {
        handleScheduleDeleteSubmit(selectedSchedule.id, refresh, setRefresh, setSchedules);
        handleClickClose();
    };

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const handleDeleteClick = () => {
        setShowConfirmPopup(true);
    };

    const handleConfirmDelete = () => {
        setShowConfirmPopup(false);
        onDeleteSubmit(); // Call the delete function
    };

    const handleCancelDelete = () => {
        setShowConfirmPopup(false);
    };

    const handleClickClose = () => {
        setMissionId('');
        setOnHour('12');
        setOnMinute('00');
        setOffHour('12');
        setOffMinute('00');
        setSelectedOutlets([]);
        setRepeat([]);
        setClickedSchedule();
        setSelectedSchedule();
        setOnHourDropdownOpen(false);
        setOffHourDropdownOpen(false);
        setOnMinDropdownOpen(false);
        setOffMinDropdownOpen(false);
        setProjectedSavings(0);
        setOffAMPMDropdownValue('PM');
        setOnAMPMDropdownValue('AM');
        setScheduleError(false);
        handleClose();
    };


    return (
        <Modal open={open} onClose={handleClickClose}>
            <Box sx={styles.container} >
                <div style={styles.innerContainer}>
                    <div style={styles.headerContainer}>
                        <div
                            className="titleLarge"
                            style={styles.headerText}
                        >
                            {!edit ? 'Create a New Schedule' : selectedSchedule === null ? 'Select a Schedule to Edit' : 'Edit Schedule'}
                        </div>
                        <IconButton
                            iconName='cancel-1'
                            tone='dark'
                            size={24}
                            onClick={handleClickClose}
                        />
                    </div>
                    <TextField
                        label="Schedule Name"
                        placeholder={
                            selectedSchedule == null ?
                                "Enter schedule name"
                                :
                                selectedSchedule.missionId !== '' ?
                                    selectedSchedule.missionId :
                                    child_org_data.find(org => org.uid === selectedSchedule.orgID)?.name
                        }
                        required
                        value={missionId}
                        onChange={(value) => {
                            setMissionId(value);
                            setScheduleError(false)
                        }}
                        size="medium"
                        background='light'
                    />
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: 'column',
                    }}>
                        <div style={{
                            fontSize: '14px',
                            color: black,
                            textAlign: 'center',
                            alignSelf: 'center',
                            paddingBottom: '10px'
                        }}>
                            Projected Weekly Savings:
                            <span style={{ fontWeight: 'bold', color: primary500 }}> ${projectedSavings.toFixed(2)}</span>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <SavingsGraphicLegend />
                            <ScheduleSavingsGraphic
                                projectedCost={projectedCost}
                                projectedSavings={projectedSavings}
                                width={300}
                                height={30}
                            />
                        </div>
                    </div>
                    {/* On Block */}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                        <Dropdown
                            list={hours_arr}
                            value={onHour}
                            onSelect={onHourDropdownClick}
                            setIsDropdownOpen={setOnHourDropdownOpen}
                            isDropdownOpen={onHourDropdownOpen}
                            label={'On Hour'}
                            size={100}
                            background="light"
                        />
                        <div style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop: '10px',
                        }}>
                            :
                        </div>
                        <Dropdown
                            list={minutes_arr}
                            value={onMinute}
                            onSelect={onMinDropdownClick}
                            setIsDropdownOpen={setOnMinDropdownOpen}
                            isDropdownOpen={onMinDropdownOpen}
                            label={'On Minute'}
                            size={100}
                            background="light"
                        />
                        <div style={{ marginLeft: '15px', width: 100 }}>
                            <Dropdown
                                list={AMPMDropdownArr}
                                value={onAMPMDropdownValue}
                                onSelect={onAMPMDropdownClick}
                                setIsDropdownOpen={setOnAMPMDropdownOpen}
                                isDropdownOpen={onAMPMDropdownOpen}
                                label={'AM/PM'}
                                size={75}
                                background="light"
                            />
                        </div>
                    </div>
                    {/* Off Block */}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Dropdown
                            list={hours_arr}
                            value={offHour}
                            onSelect={offHourDropdownClick}
                            setIsDropdownOpen={setOffHourDropdownOpen}
                            isDropdownOpen={offHourDropdownOpen}
                            label={'Off Hour'}
                            size={100}
                            background="light"
                        />
                        <div style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop: '10px',
                        }}>
                            :
                        </div>
                        <Dropdown
                            list={minutes_arr}
                            value={offMinute}
                            onSelect={offMinDropdownClick}
                            setIsDropdownOpen={setOffMinDropdownOpen}
                            isDropdownOpen={offMinDropdownOpen}
                            label={'Off Minute'}
                            size={100}
                            background="light"
                        />
                        <div style={{ marginLeft: '15px', width: 100 }}>
                            <Dropdown
                                list={AMPMDropdownArr}
                                value={offAMPMDropdownValue}
                                onSelect={offAMPMDropdownClick}
                                setIsDropdownOpen={setOffAMPMDropdownOpen}
                                isDropdownOpen={offAMPMDropdownOpen}
                                label={'AM/PM'}
                                size={75}
                                background="light"
                            />
                        </div>
                    </div>
                    {/* Outlets */}
                    <FormControl sx={{ width: "485px", marginTop: '10px' }}>
                        <Select
                            //labelId="outlet-label"
                            multiple
                            value={selectedOutlets}
                            onChange={(e) => {
                                setSelectedOutlets(e.target.value);
                                setScheduleError(false);
                            }}
                            //label="Outlets"
                            required
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {selected.map((outlet) => (
                                        <Chip key={outlet.id} label={outlet.name} sx={{ margin: '2px' }} />
                                    ))}
                                </Box>
                            )}
                        //selected.length ? selected.join(", ") : "Select Outlets"
                        //}
                        >
                            {outlets.map((outlet) => (
                                <MenuItem key={outlet.id} value={outlet}>
                                    {/* {outlet.name} */}
                                    <Checkbox checked={selectedOutlets.includes(outlet)} />
                                    <ListItemText primary={outlet.name} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Select outlets to schedule</FormHelperText>
                    </FormControl>
                    {/* Repeat */}
                    <div style={{
                        marginTop: '5px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div
                            className="labelMedium"
                            style={{ color: neutral800 }}
                        >
                            Days:
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            marginTop: '10px',
                        }}>
                            {weekArr.map(day => (
                                <div
                                    key={day}
                                    onClick={() => toggleDay(day)}
                                    style={{
                                        borderWidth: '1px',
                                        borderColor: repeat.includes(day) ? primary500 : neutral800,
                                        borderStyle: 'solid',
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                        marginLeft: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        color: repeat.includes(day) ? white : neutral800,
                                        backgroundColor: repeat.includes(day) ? primary500 : white,
                                    }}
                                    className="buttonMedium"
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: '10px',
                        flexDirection: 'column'
                    }}>
                        <CustomButton
                            filled={true}
                            label={schedule?.status === 'New' ? "Create Schedule" : schedule?.status === undefined ? 'Create Schedule' : "Update Schedule"}
                            onClick={onSubmit}
                            size="medium"
                            disabled={
                                (
                                    missionId !== '' &&
                                    (onHour !== offHour || onMinute !== offMinute || onAMPMDropdownValue !== offAMPMDropdownValue) &&
                                    selectedOutlets.length !== 0 &&
                                    repeat.length !== 0 &&
                                    !scheduleError
                                ) ?
                                    false :
                                    true
                            }
                        />
                        {scheduleError ?
                            <div
                                className="labelLarge"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: orange300,
                                    height: '20px',
                                    marginTop: '5px'
                                }}
                            >
                                This schedule conflicts with an existing schedule.
                            </div>
                            :
                            <div
                                className="labelLarge"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    color: orange300,
                                    height: '20px'
                                }}
                            >
                            </div>
                        }
                    </div>
                    {edit && (
                        <>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: '-10px',
                                paddingBottom: '20px',
                                // marginBottom: '20px',
                            }}>
                                <CustomButton
                                    filled={false}
                                    label="Delete Schedule"
                                    onClick={handleDeleteClick}
                                    size="medium"
                                />
                            </div>
                            {showConfirmPopup && (
                                <div style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        backgroundColor: 'white',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                                        textAlign: 'center',
                                        width: '400px'
                                    }}>
                                        <div className="labelLarge">
                                            Are you sure you want to delete this schedule?
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            marginTop: '20px'
                                        }}>
                                            <CustomButton
                                                filled={true}
                                                label="Yes, Delete"
                                                onClick={handleConfirmDelete}
                                                size="small"
                                            />
                                            <CustomButton
                                                filled={false}
                                                label="Cancel"
                                                onClick={handleCancelDelete}
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    );
};