import React, { useState, useEffect } from "react";
import { LineChart } from '@mui/x-charts/LineChart';
import { ChartsYAxis } from '@mui/x-charts';
import { useLocation, useOutletContext } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import {
    aggregateByHourWattHour,
    aggregateByMonthWattHour,
    calculateTotalAverage,
    filterAverageUsage
} from "../../../functions/wattHours";
import CustomIcon from "../../icon";
import { black } from "../../../lib/rebrandColors/colors";

export default function DailyUsageChart({
    layoutProps,
    outletID
}) {
    const location = useLocation();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateString, setDateString] = useState(`${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`);
    const [dayString, setDayString] = useState(selectedDate.toLocaleString('en-us', { weekday: 'short' }));
    // for date picker
    const handleDateChange = (newValue) => {
        console.log('[daily usage chart] date change with moment: ', newValue.toDate());
        setSelectedDate(newValue.toDate());
    };
    // updates data when date picker changes
    useEffect(() => {
        setDateString(`${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`);
        setDayString(selectedDate.toLocaleString('en-us', { weekday: 'short' }));
    }, [selectedDate]);

    const today = new Date(); // Current date in local timezone

    //Next couple of lines account for single outlet displays instead of aggregated outlet displays 
    let wattHours = layoutProps.wattHours
    let averageUsage = layoutProps.averageUsage

    if (outletID) {
        wattHours = wattHours.filter(
            (item) => item.outletID === outletID
        );
        averageUsage = averageUsage.filter(
            (item) => item.outletID === outletID
        );
    };

    if (
        layoutProps.averageLoading === false &&
        layoutProps.wattsLoading === false &&
        layoutProps.savingsLoading === false
    ) {
        const wattHoursObject = wattHours.reduce((acc, item) => {
            const { date, outletID, hourlyWattHour } = item;
            if (!acc[date]) {
                acc[date] = [];
            };
            acc[date].push({
                outletID,
                hourlyWattHour,
            });
            return acc;
        }, {});
        //returns all of the data in the outlet's averageUsage document
        const filteredAverageUsage = filterAverageUsage(averageUsage, wattHours);
        //returns the average_savings_data for each outlet
        const dailyAverage = calculateTotalAverage(filteredAverageUsage);

        //NOTE: aggregators pre convert to kWhs
        const multiWeekData = aggregateByMonthWattHour(wattHoursObject);
        // ensure that this passes as '2024-11-20'
        const dailyWattHoursData = aggregateByHourWattHour(wattHoursObject, dateString);
        const dailyWattHoursDataWithAverage = dailyWattHoursData.map((entry, index) => ({
            ...entry,
            averageWattHour: dailyAverage[dayString][index]
        }));

        return (
            <div style={{
                minWidth: '500px',
                height: location.pathname !== '/' || outletID ? '450px' : '425px',
                margin: '.5%',
                display: "flex",
                //alignItems:'center',
                flexDirection:'column',
                paddingLeft:'20px'
            }}>
                {(location.pathname === '/' && !outletID) &&
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '20px',
                        marginTop: '40px',
                    }}>
                        <CustomIcon
                            name="connect"
                            color={black}
                            size={30}
                        />
                        <div
                            className="bodyLarge"
                            style={{
                                marginLeft: '8px',
                            }}
                        >
                            Today's Usage
                        </div>
                    </div>
                    // :
                    // <div style={{height: '0px'}}></div>
                }
                {(location.pathname !== '/' || outletID) &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight:'20px',
                        marginTop: '10px',
                        //marginBottom: '10px',
                    }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Date"
                                sx={{width:'150px'}}
                                value={moment(selectedDate)}
                                onChange={handleDateChange}
                            />
                        </LocalizationProvider>
                    </div>
                }
                <LineChart
                    dataset={dailyWattHoursDataWithAverage}
                    sx={{
                        '& .MuiLineElement-series-averageWattHour': {
                            stroke: '#E56208',              // Line color
                            strokeDasharray: '5 5',       // Dashed line pattern
                            strokeWidth: 3,                // Line width
                        },
                        '& .MuiAreaElement-series-averageWattHour': {
                            fill: "url('#averageGradient')",
                        },
                        '& .MuiAreaElement-series-totalWattHour': {
                            fill: "url('#usageGradient')",
                        },
                    }}
                    series={[
                        {
                            dataKey: "averageWattHour",
                            id: 'averageWattHour',
                            label: 'Baseline Usage',
                            color: "#E56208",
                            showMark: true,
                            area: true,
                            curve: 'catmullRom',
                            valueFormatter: (v) => `${v.toFixed(1)} kWh`,
                        },
                        {
                            dataKey: "totalWattHour",
                            id: "totalWattHour",
                            label: 'Current Usage',
                            color: '#2789FF',
                            showMark: true,
                            area: true,
                            curve: 'catmullRom',
                            valueFormatter: (v) => `${v.toFixed(1)} kWh`,
                        },
                    ]}
                    xAxis={[
                        {
                            dataKey: "hour",
                            valueFormatter: (value) => {
                                const hour = value % 12 || 12;
                                const ampm = value < 12 ? 'AM' : 'PM';
                                return `${hour}:00 ${ampm}`;
                            },
                            min: 0,
                            max: 23,
                        },
                    ]}
                >
                    <ChartsYAxis
                        label="kWhs" // Y-axis label
                        labelStyle={{ transform: location.pathname !== '/' || outletID ? 'translateY(-160px)' : 'translateY(-145px)', }}
                    />
                    <defs>
                        <linearGradient id="averageGradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#E56208" stopOpacity={.5} />
                            <stop offset="50%" stopColor="#E56208" stopOpacity={.25} />
                            <stop offset="100%" stopColor="#E56208" stopOpacity={.05} />
                        </linearGradient>
                        <linearGradient id="usageGradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#2789FF" stopOpacity={.95} />
                            <stop offset="50%" stopColor="#2789FF" stopOpacity={.55} />
                            <stop offset="100%" stopColor="#2789FF" stopOpacity={.3} />
                        </linearGradient>
                    </defs>
                </LineChart>
            </div >
        );
    } else {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '50px'
            }}>
                <CircularProgress />
            </div>
        );
    };
};