import React, { useEffect, useState } from "react";
import { Box, Button, Tabs, Tab } from "@mui/material";
import { elevationLight1, neutral400, primary500 } from "../../lib/rebrandColors/colors";
import MonthlyCalendar from "../monthlyCalendar";
import TimePicker from "../timePicker";
import DailyScheduleView from "../dailyScheduleView";
import WeeklyScheduleView from "../weeklyScheduleView";
import ScheduleForm from "../forms/scheduleForm";
import SpecialEventForm from "../forms/specialEventForm";
import { calculateTotalProjectedSavings, calculateTotalProjectedCost } from "../../functions/savings";
import { black } from "../../lib/rebrandColors/colors";
import SavingsGraphicLegend from "../savingsGraphicLegend";
import MultiScheduleSavingsGraphic from "../multiScheduleSavingsGraphic";
import { useLocation } from "react-router-dom";

export default function CalendarContainer({
  layoutProps,
  filteredEvents,
  filteredSchedules,
  subOrgDropdownValue,
  filter = 'Accepted',
}) {
  const [view, setView] = useState("week"); // Default to month view
  const [selectedDate, setSelectedDate] = useState(new Date()); // Store the date selected by TimePicker
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [clickedSchedule, setClickedSchedule] = useState();
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [scheduleEdit, setScheduleEdit] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState();
  const [projectedSavings, setProjectedSavings] = useState(0);
  const [projectedSavingsObject, setProjectedSavingsObject] = useState({});
  const [projectedCost, setProjectedCost] = useState(0);
  const [projectedCostObject, setProjectedCostObject] = useState({});

  const location = useLocation();

  // Update date based on TimePicker selection
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // Handle view change
  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  // Modal open/close handlers
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const scheduleClick = (schedule) => {
    setIsScheduleOpen(true);
    setScheduleEdit(true);
    setClickedSchedule(schedule);
  };
  const openScheduleModal = (edit) => {
    setIsScheduleOpen(true);
    setScheduleEdit(edit);
  };
  const closeScheduleModal = () => {
    setIsScheduleOpen(false);
    setClickedSchedule();
  };

  useEffect(() => {
    calculateTotalProjectedSavings(filteredSchedules, setProjectedSavings, filter, setProjectedSavingsObject);
    calculateTotalProjectedCost(filteredSchedules, setProjectedCost, filter, setProjectedCostObject);
  }, [filteredSchedules]);

  return (
    <Box sx={{
      width: "1200px", // Fixed width
      height: location.pathname === "/schedules" ? "792px" : "592px", // Fixed height
      padding: "24px", // Padding inside the container for spacing
      backgroundColor: "#F9F9F9",
      borderRadius: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowY: "scroll",
    }}>
      {/* Header */}
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%", // Stretch header to full width of the container
      }}>
        {/* Tabs for View Selection */}
        <div style={{ width: '33%', alignSelf: 'flex-start', }}>
          <Tabs
            value={view}
            onChange={handleViewChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: primary500, // Red underline for the selected tab
              },
              "& .MuiTab-root": {
                textTransform: "none",
                minWidth: "auto",
                // padding: "0 16px",
                color: neutral400, // Default color for unselected tabs
                fontFamily: "IBM Plex Mono",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              },
              "& .MuiTab-root.Mui-selected": {
                color: primary500, // Red color for the selected tab text
              },
            }}
          >
            {filter !== 'New' &&
              <Tab label="Month" value="month" />
            }
            <Tab label="Week" value="week" />
            {filter !== 'New' &&
              <Tab label="Day" value="day" />
            }
          </Tabs>
        </div>
        <div style={{ alignSelf: 'flex-start', marginBottom: '20px', }}>
          {view === 'week' && projectedSavings.toFixed(2) > 0 &&
            <div style={{ borderRadius: 25, boxShadow: elevationLight1, padding: '10px' }}>
              <div style={{
                fontSize: '18px',
                color: black,
                textAlign: 'center',
                marginBottom: '10px',
                marginTop: '15px'
              }}>
                Projected Total Weekly Savings:
                <span style={{ fontWeight: 'bold', color: primary500 }}> ${projectedSavings.toFixed(2)}</span>
              </div>
              <SavingsGraphicLegend />
              <MultiScheduleSavingsGraphic
                projectedCostObject={projectedCostObject}
                projectedSavingsObject={projectedSavingsObject}
                width={725}
                height={175}
                name={layoutProps.orgData[0]?.name}
              />
            </div>
          }
        </div>
        {/* Conditional Button */}
        {view === "week" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: '33%',
              alignSelf: 'flex-start',
            }}
          >
            <Button
              variant="contained"
              onClick={() => openScheduleModal(false)}
              sx={{
                backgroundColor: primary500,
                color: "#FFFFFF",
                fontWeight: "bold",
                textTransform: "none",
                width: '150px',
                padding: "8px 16px",
                borderRadius: "24px",
                display: "flex",
                // alignItems: "center",
                "&:hover": {
                  backgroundColor: "#D32F2F",
                },
                cursor: layoutProps.role === "view" ? "not-allowed" : "pointer",
              }}
              disabled={layoutProps.role === "view" ? true : false}
            >
              + Add Schedule
            </Button>
          </Box>
        ) : (
          <div style={{ width: '33%', justifyContent: 'flex-end', display: 'flex', alignSelf: 'flex-start' }}>
            <Button
              variant="outlined"
              onClick={openModal} // Opens modal on click
              sx={{
                borderColor: "#E50914",
                color: "#E50914",
                fontWeight: "bold",
                textTransform: "none",
                padding: "8px 16px",
                borderRadius: "24px",
                "&:hover": {
                  borderColor: "#E50914",
                  backgroundColor: "transparent",
                },
                cursor: layoutProps.role === "view" ? "not-allowed" : "pointer",
              }}
              disabled={layoutProps.role === "view" ? true : false}
            >
              + Add special event
            </Button>
          </div>
        )}
      </Box>

      {/* Time Picker */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <TimePicker
          timeSpan={view.charAt(0).toUpperCase() + view.slice(1)}
          setTime={handleDateChange}
        />
      </Box>

      {/* Calendar Content */}
      {view === "month" && (
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            padding: "16px", // Space around the calendar inside the container
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            scrollbarWidth: "none",
            justifyContent: "center",
          }}
        >
          <MonthlyCalendar
            date={selectedDate}
            events={filteredEvents}
            setIsEventEditModalOpen={setIsModalOpen}
            setSelectedEvent={setSelectedEvent}
          />
        </Box>
      )}
      {view === "week" &&
        (filteredSchedules?.length === 0 ? (
          <div
            className="headlineMedium"
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "50px",
              color: "#999999",
            }}
          >
            No schedules today
          </div>
        ) : (
          <Box sx={{
            width: "100%",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}>
            <WeeklyScheduleView
              filteredSchedules={filteredSchedules}
              org={subOrgDropdownValue ? subOrgDropdownValue : "All"}
              outlets={layoutProps.outlets}
              orgData={layoutProps.orgData}
              scheduleClick={scheduleClick}
              filter={filter}
            />
          </Box>
        ))}
      {view === "day" && (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <DailyScheduleView
            width={layoutProps.windowDimensions.width}
            height={"615px"}
            layoutProps={layoutProps}
            scheduleClick={scheduleClick}
            setClickedSchedule={setClickedSchedule}
          />
        </Box>
      )}

      {/* Special Event Form Modal */}
      <SpecialEventForm
        open={isModalOpen}
        handleClose={closeModal}
        orgData={layoutProps.orgData}
        schedules={filteredSchedules}
        refresh={layoutProps.refresh}
        setRefresh={layoutProps.setRefresh}
        isOverviewAccount={layoutProps.isOverviewAccount}
        child_org_data={layoutProps.orgData}
        outlets={layoutProps.outlets}
        event={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />
      {/* Add Schedule Form Modal */}
      <ScheduleForm
        edit={scheduleEdit}
        open={isScheduleOpen}
        handleClose={closeScheduleModal}
        outlets={layoutProps.outlets}
        child_org_data={layoutProps.orgData}
        setSchedules={layoutProps.setSchedules}
        orgID={layoutProps.orgID}
        selectedOrgID={layoutProps.selectedOrgID}
        refresh={layoutProps.refresh}
        setRefresh={layoutProps.setRefresh}
        schedule={clickedSchedule}
        setClickedSchedule={setClickedSchedule}
        schedules={filteredSchedules}
      />
    </Box>
  );
}
