import {
  query,
  collection,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import db from "../firebase";

export const fetchWattHours = (
  orgIDs,
  setWattHours,
  setWattsLoading,
  outletID = null,
  applianceType = null
) => {
  let wattHours = [];
  const today = new Date();
  const dayOfWeek = today.getDay();
  const previousMonday = new Date(today);
  previousMonday.setDate(today.getDate() - ((dayOfWeek + 6) % 7));
  const fiveWeeksAgo = new Date(previousMonday);
  // Note: trying with 6 months, 180, replace 180 with 35 for 5 weeks
  fiveWeeksAgo.setDate(previousMonday.getDate() - 35);
  const year = fiveWeeksAgo.getFullYear();
  const month = String(fiveWeeksAgo.getMonth() + 1).padStart(2, "0");
  const day = String(fiveWeeksAgo.getDate()).padStart(2, "0");
  const fiveWeeksAgoString = `${year}-${month}-${day}`;
  let wattHoursQ;
  //get new wattHours collection
  const getWattHours = async () => {
    setWattsLoading(true);
    if (outletID) {
      // ----------------> If there is an outletID, we need to filter
      wattHoursQ = query(
        collection(db, "hourlyWattHours"),
        where("orgID", 'in', orgIDs),
        where("date", ">=", fiveWeeksAgoString),
        where("outletID", "==", outletID)
      );
    } else if (
      applianceType &&
      applianceType !== "" &&
      applianceType !== "All Outlets"
    ) {
      wattHoursQ = query(
        collection(db, "hourlyWattHours"),
        where("orgID", 'in', orgIDs),
        where("date", ">=", fiveWeeksAgoString),
        where("productType", "==", applianceType === "N/A" ? "" : applianceType)
      );
    }
    // TODO: this query should pull based on orgID can add orgID pull section once the whole database is in the new collection
    else {
      //console.log("Not filtered")
      wattHoursQ = query(
        collection(db, "hourlyWattHours"),
        where("orgID", 'in', orgIDs),
        where("date", ">=", fiveWeeksAgoString)
      );
    };
    
    const wattHoursQuerySnapshot = await getDocs(wattHoursQ);

    wattHoursQuerySnapshot.forEach((doc) => {
      wattHours.push(doc.data());
    });
    
    const sortedWattHours = wattHours.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    
    setWattHours(sortedWattHours);
    setWattsLoading(false);
  };
  getWattHours();
};

export const filterByDate = (array, targetDate) => {
  return array.filter((item) => item.date === targetDate);
};

export const filterByDateRange = (array, startDate, endDate) => {
  // Convert startDate and endDate to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  return array.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= start && itemDate <= end;
  });
};

export const sumValues = (array) => {
  return array.reduce((accumulator, currentValue) => {
    // Check if currentValue is not -1
    if (currentValue !== -1) {
      return accumulator + currentValue;
    } else {
      return accumulator;
    }
  }, 0);
};

export const sumHoursByOutletID = (array) => {
  return array.reduce((accumulator, currentItem) => {
    const { outletID, hourlyWattHour } = currentItem;
    const onlineValues = hourlyWattHour.filter((num) => num >= 0);
    const hoursSum = sumValues(onlineValues);

    // If outletID already exists, add the sum of hours to the existing value
    if (accumulator[outletID]) {
      accumulator[outletID] += hoursSum;
    } else {
      // If outletID does not exist, initialize it with the sum of hours
      accumulator[outletID] = hoursSum;
    }

    return accumulator;
  }, {});
};

export const sumHoursByOutletName = (array) => {
  return array.reduce((accumulator, currentItem) => {
    const { name, hourlyWattHour } = currentItem;
    const onlineValues = hourlyWattHour.filter((num) => num >= 0);
    const hoursSum = sumValues(onlineValues);

    // If outletID already exists, add the sum of hours to the existing value
    if (accumulator[name]) {
      accumulator[name] += hoursSum;
    } else {
      // If outletID does not exist, initialize it with the sum of hours
      accumulator[name] = hoursSum;
    }

    return accumulator;
  }, {});
};

export const fetchAverageUsage = async (
  orgIDs,
  setAverageUsage,
  setAverageLoading,
  outletID = null,
  applianceType = null
) => {
  
  let averageUsage = [];
  let averageUsageQ;
  //get new wattHours collection
  const getAverageUsage = async () => {
    setAverageLoading(true);
    if (outletID) {
      // ----------------> If there is an outletID, we need to filter
      averageUsageQ = query(
        collection(db, "averageUsage"),
        where("orgID", 'in', orgIDs),
        where("outletID", "==", outletID)
      );
    } else if (
      applianceType &&
      applianceType !== "" &&
      applianceType !== "All Outlets"
    ) {
      averageUsageQ = query(
        collection(db, "averageUsage"),
        where("orgID", 'in', orgIDs),
        where("productType", "==", applianceType === "N/A" ? "" : applianceType)
      );
    } else {
      //console.log("Not filtered")
      averageUsageQ = query(
        collection(db, "averageUsage"),
        where("orgID", 'in', orgIDs),
      );
    };
    const averageUsageQuerySnapshot = await getDocs(averageUsageQ);

    averageUsageQuerySnapshot.forEach((doc) => {
      averageUsage.push(doc.data());
    });
    setAverageUsage(averageUsage);
    setAverageLoading(false);
  };
  getAverageUsage();
};

export function sumArrayIgnoreNegatives(arr) {
  let total = 0;
  for (let num of arr) {
    if (num !== -1) {
      total += num;
    };
  };
  return total;
};

// NOTE: used to assemble savings, usage, or average data together for graphing
export function aggregateByMonthCost(data) {
  const monthlySavings = {};

  Object.keys(data).forEach((date) => {
    const [year, month] = date.split("-"); // Extract year and month from the date
    const key = `${year}-${month}`;
    const totalCostSavings = data[date].reduce(
      (sum, outlet) => sum + outlet.savings.costSavings,
      0
    );
    if (!monthlySavings[key]) {
      monthlySavings[key] = 0;
    };
    monthlySavings[key] += totalCostSavings;
  });
  return Object.entries(monthlySavings)
    .map(([key, totalCostSavings]) => {
      const [year, month] = key.split("-");
      return {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        totalCostSavings,
      };
    }).sort((a, b) => {
      // Sort by year first, then by month
      if (a.year === b.year) {
        return a.month - b.month;
      };
      return a.year - b.year;
    });
};
export function aggregateByMonthCO2(data) {
  const monthlySavings = {};

  Object.keys(data).forEach((date) => {
    const [year, month] = date.split("-"); // Extract year and month from the date
    const key = `${year}-${month}`;
    const totalCostSavings = data[date].reduce(
      (sum, outlet) => sum + outlet.savings.co2eSavings,
      0
    );
    if (!monthlySavings[key]) {
      monthlySavings[key] = 0;
    };
    monthlySavings[key] += totalCostSavings;
  });
  return Object.entries(monthlySavings)
    .map(([key, totalCostSavings]) => {
      const [year, month] = key.split("-");
      return {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        totalCostSavings,
      };
    }).sort((a, b) => {
      // Sort by year first, then by month
      if (a.year === b.year) {
        return a.month - b.month;
      };
      return a.year - b.year;
    });
};
export function aggregateByMonthEnergy(data) {
  const monthlySavings = {};

  Object.keys(data).forEach((date) => {
    const [year, month] = date.split("-"); // Extract year and month from the date
    const key = `${year}-${month}`;
    const totalCostSavings = data[date].reduce(
      (sum, outlet) => sum + outlet.savings.energySavings,
      0
    );
    if (!monthlySavings[key]) {
      monthlySavings[key] = 0;
    };
    monthlySavings[key] += totalCostSavings;
  });
  return Object.entries(monthlySavings)
    .map(([key, totalCostSavings]) => {
      const [year, month] = key.split("-");
      return {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        totalCostSavings,
      };
    }).sort((a, b) => {
      // Sort by year first, then by month
      if (a.year === b.year) {
        return a.month - b.month;
      };
      return a.year - b.year;
    });
};
export function aggregateByMonthWattHour(data) {
  const monthlyWattHour = {};
  Object.keys(data).forEach((date) => {
    const [year, month] = date.split("-");
    const key = `${year}-${month}`;
    const totalWattHour = data[date].reduce((sum, outlet) => {
      // Sum the values in hourlyWattHour array, ignoring -1s
      const outletTotal = outlet.hourlyWattHour
        .filter((value) => value !== -1) // Exclude -1s
        .reduce((acc, value) => acc + value, 0); // Sum the valid values
      return sum + outletTotal; // Add to the running total
    }, 0);

    if (!monthlyWattHour[key]) {
      monthlyWattHour[key] = 0;
    };
    monthlyWattHour[key] += totalWattHour;
  });
  return Object.entries(monthlyWattHour)
    .map(([key, totalWattHour]) => {
      const [year, month] = key.split("-");
      return {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        totalWattHour,
      };
    }).sort((a, b) => {
      // Sort by year first, then by month
      if (a.year === b.year) {
        return a.month - b.month;
      };
      return a.year - b.year;
    });
};
export function aggregateByHourWattHour(data, targetDate) {
  const hourlyTotals = Array(24).fill(0);

  // Ensure the targetDate exists in the data
  if (!data[targetDate]) {
    return hourlyTotals.map((total, hour) => ({
      hour,
      totalWattHour: total, // Default to 0 for missing date
    }));
  };

  // Iterate over outlets for the target date
  data[targetDate].forEach((outlet) => {
    outlet.hourlyWattHour.forEach((value, hour) => {
      if (value !== -1) {
        hourlyTotals[hour] += value / 1000;
      };
    });
  });


  // Map the totals into the desired format for the chart
  return hourlyTotals.map((total, hour) => ({
    hour,
    totalWattHour: total,
  }));
};
export function filterAverageUsage(averageUsage, wattHours) {
  return averageUsage.filter((outlet) =>
    wattHours.some((outletUsage) => outletUsage.outletID === outlet.outletID)
  );
};
export function calculateTotalAverage(filteredAverageUsage) {
  // Initialize the totalAverage object with days of the week as keys
  const totalAverage = {
    Mon: Array(24).fill(0),
    Tue: Array(24).fill(0),
    Wed: Array(24).fill(0),
    Thu: Array(24).fill(0),
    Fri: Array(24).fill(0),
    Sat: Array(24).fill(0),
    Sun: Array(24).fill(0),
  };

  filteredAverageUsage.forEach((outlet) => {
    const { average_savings_data } = outlet;

    // Accumulate the values for each day and hour
    Object.keys(average_savings_data).forEach((day) => {
      average_savings_data[day].forEach((value, hour) => {
        totalAverage[day][hour] += value / 1000;
      });
    });
  });

  return totalAverage;
};