import React from "react";
import { grey600, primary500 } from "../../lib/rebrandColors/colors";
import { useLocation } from "react-router-dom";
import Chip from "../chip";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";

export default function OutletCard({
    outlet,
    handleRowClick,
    i,
    width,
}) {

    const location = useLocation();
    return (
        <div
            style={{
                display: "flex",
                width: width ? width : "480px",
                minHeight: "88px",
                backgroundColor: "#EEEEEE",
                borderRadius: "16px",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "5px",
                marginTop: i == 0 ? "2px" : "16px",
                cursor: "pointer",
            }}
            onClick={() => handleRowClick(outlet, i)}
            key={outlet.id}
        >
            <div style={{
                width: "90%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            }}>
                {/* name and on/off column */}
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: location.pathname === "/appliances" ? '30%' : '',
                }}>
                    <div
                        className="labelMedium"
                        style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: grey600,
                        }}
                    >
                        {outlet.name}
                    </div>
                    <div
                        className="labelMedium"
                        style={{
                            fontSize: "12px",
                            color: "#666666",
                        }}
                    >
                        {outlet.orgName}
                    </div>
                    <div
                        className="labelMedium"
                        style={{
                            fontSize: "12px",
                            color: "#666666",
                        }}
                    >
                        Today: {outlet.dailyUsage.toFixed(2)} kWh
                    </div>
                    <div
                        className="labelMedium"
                        style={{
                            color: outlet.status == "Off" ? primary500 : "green",
                            fontSize: "12px",
                        }}
                    >
                        {outlet.status}
                    </div>
                </div>
                {/* product type display */}
                <div style={{
                    width: '54%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center'
                }}>
                    {location.pathname === "/appliances" && (
                        <div>
                            <Chip
                                label={
                                    outlet.productType == ""
                                        ? "N/A"
                                        : outlet.productType
                                }
                                alert={false}
                                iconName="plug"
                            />
                        </div>
                    )}
                    {/* connection status display */}
                    {location.pathname === "/appliances" &&
                        (outlet.wifiStatus === "Connected" ? (
                            <div>
                                <Chip
                                    label={outlet.wifiStatus}
                                    alert={false}
                                    icon={<WifiIcon style={{ color: "#009C49" }} />}
                                />
                            </div>
                        ) : outlet.wifiStatus === "Disconnected" ? (
                            <Chip
                                label={outlet.wifiStatus}
                                alert={true}
                                icon={<WifiOffIcon style={{ color: "#FF6D2A" }} />}
                            />
                        ) : (
                            <Chip
                                label={outlet.wifiStatus}
                                alert={true}
                                icon={
                                    <WifiLockIcon
                                        style={{ marginLeft: "3%", color: "orange" }}
                                    />
                                }
                            />
                        ))
                    }
                </div>
                {/* savings column */}
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    width: '15%',
                }}>
                    <div
                        className="buttonSmall"
                        style={{
                            fontSize: "10px",
                            lineHeight: "10px",
                            textAlign: "center",
                        }}
                    >
                        Estimated
                        <br />
                        savings:
                    </div>
                    <div style={{
                        width: "63px",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "24px",
                        backgroundColor: "#FFFFFF",
                        marginTop: "5px",
                    }}>
                        <div
                            className="buttonSmall"
                            style={{
                                color: "#E50914",
                                fontSize: "12px",
                                fontWeight: "700",
                            }}
                        >
                            ${outlet.costSavings.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};