import {
    getDocs,
    collection,
    doc,
    deleteDoc,
    updateDoc,
    setDoc,
    addDoc,
    query,
    where,
} from "firebase/firestore";
import db from "../firebase";

// note: called in schedule.js
export const fetchOutletsData = (orgID, setOutlets) => {
    const getOutletsData = async () => {
        //ensures that function doesn't run without an organization ID being present
        if (orgID !== null) {
            const directOutlets = await getDocs(collection(doc(db, 'organizations', orgID), 'Outlets'));
            const directOutletsData = directOutlets.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setOutlets(directOutletsData);
            localStorage.setItem("directOutletsData", JSON.stringify(directOutletsData))
        };
    };
    getOutletsData();
};

// delete function for missions
export const deletePress = (
    id,
    type,
    orgID,
    setHideAccept,
    setHideDecline,
    setAcceptOrDecline,
    setConfirmDelete,
) => {
    // console.log('declining mission id', id);
    if (type === 'Schedule') {
        deleteDoc(doc(db, 'organizations', orgID, 'Schedules', id));
    };
    if (type === 'Mission') {
        updateDoc(doc(db, 'organizations', orgID, 'Missions', id), {
            status: 'Passed'
        });
    };
    if (type === 'Schedule from Mission') {
        updateDoc(doc(db, 'organizations', orgID, 'Missions', id), {
            status: 'Passed'
        });
        deleteDoc(doc(db, 'organizations', orgID, 'Schedules', id));
    };
    setHideAccept(true);
    setHideDecline(true);
    setAcceptOrDecline('Decline');
    setConfirmDelete(false);
};

// accept function for missions
export const acceptPress = (
    type,
    orgID,
    id,
    schedules,
    idleHours,
    outletsSelected,
    setShowError,
    missionId,
    names,
    repeat,
    potentialPriceSavings,
    potentialCO2Savings,
    potentialEnergySavings,
    description,
    setHideAccept,
    setHideDecline,
    setAcceptOrDecline,
    allSchedules,
    setSchedulesDisplay,
    offHour,
    onHour,
) => {
    if (type === 'Schedule') {
        updateDoc(doc(db, 'organizations', orgID, 'Schedules', id), {
            status: 'Accepted'
        });
    };
    if (type === 'Mission') {
        let index = 0;
        for (const schedule of schedules) {
            // console.log('accepting mission id', id, schedules.length);
            // eslint-disable-next-line no-loop-func
            if (((offHour >= schedule.offHour) || (onHour <= schedule.onHour)) && (schedule.outletIds.some(outletId => outletsSelected.includes(outletId)))) {
                //don't let mission be accepted
                console.log('fail index', index);
                setShowError(true);
                break;
            } else if (index === (schedules.length - 1)) {
                //should only run in we've checked all schedules for overlap
                updateDoc(doc(db, 'organizations', orgID, 'Missions', id), {
                    status: 'Accepted'
                });
                setDoc(doc(db, 'organizations', orgID, 'Schedules', id), {
                    id: id,
                    missionId: missionId,
                    names: names,
                    outletsSelected: outletsSelected,
                    offHour: offHour,
                    offMinute: 0,
                    onHour: onHour,
                    onMinute: 0,
                    repeat: repeat,
                    potentialPriceSavings: potentialPriceSavings || 0,
                    potentialCO2Savings: potentialCO2Savings || 0,
                    potentialEnergySavings: potentialEnergySavings || 0,
                    status: 'Accepted',
                    type: 'Schedule from Mission',
                    // description: description,
                    offTaskID: '',
                    onTaskID: '',
                });
                setHideAccept(true);
                setHideDecline(true);
                setAcceptOrDecline('Accept');
                const newSchedules = [...allSchedules];
                const indexOfMission = allSchedules.findIndex(obj => obj.id === id);
                newSchedules[indexOfMission] = {
                    ...allSchedules[indexOfMission],
                    status: 'Accepted',
                };
                setSchedulesDisplay(newSchedules);
                // console.log('schedulesDisplay', schedulesDisplay);
            };
            index++;
        };
    };
};

// function to filter displayed schedules in calendar component
export const filterSchedules = (
    orgData,
    outlets,
    schedules,
    prodTypeDropdownValue,
    setFilteredSchedules,
    events,
    setFilteredEvents,
    setProdTypeDropdownValue,
    subOrgDropdownValue,
) => {
    if (orgData.length > 0) {
        const filteredOrg = orgData.find(
            (org) => org.name === subOrgDropdownValue
        );
        if (filteredOrg) {
            const newFilteredSchedules = schedules.filter((schedule) => {
                const orgMatches = schedule.orgID === filteredOrg.uid;

                // If prodTypeDropdownValue is "All", skip the product type filter; otherwise, apply it
                const productTypeMatches =
                    prodTypeDropdownValue === "All" ||
                    schedule.outletsSelected.some((outletId) => {
                        const matchingOutlet = outlets.find(
                            (outlet) =>
                                outlet.id === outletId &&
                                outlet.productType === prodTypeDropdownValue
                        );
                        return Boolean(matchingOutlet);
                    });

                // Only include schedules that match both the orgID and, if specified, the product type
                return orgMatches && productTypeMatches;
            });
            setFilteredSchedules(newFilteredSchedules);
            const newFilteredEvents = events.filter((event) => {
                const orgMatches = event.organizationID === filteredOrg.uid;
                return orgMatches;
            });
            setFilteredEvents(newFilteredEvents);
        } else if (prodTypeDropdownValue !== "All") {
            const newFilteredSchedules = schedules.filter((schedule) => {

                // If prodTypeDropdownValue is "All", skip the product type filter; otherwise, apply it
                const productTypeMatches =
                    prodTypeDropdownValue === "All" ||
                    schedule.outletsSelected.some((outletId) => {
                        const matchingOutlet = outlets.find(
                            (outlet) =>
                                outlet.id === outletId &&
                                outlet.productType === prodTypeDropdownValue
                        );
                        return Boolean(matchingOutlet);
                    });

                // Only include schedules that match both the orgID and, if specified, the product type
                return productTypeMatches;
            });
            setFilteredSchedules(newFilteredSchedules);
        } else {
            setFilteredSchedules(schedules);
            setFilteredEvents(events);
            setProdTypeDropdownValue("All");
        };
    } else {
        // filter schedules by product type only if no child orgs
        let newFilteredSchedules;
        if (prodTypeDropdownValue !== "All") {
            newFilteredSchedules = schedules.filter((schedule) =>
                schedule.outletsSelected.some((outletId) => {
                    const matchingOutlet = outlets.find(
                        (outlet) =>
                            outlet.id === outletId &&
                            outlet.productType === prodTypeDropdownValue
                    );
                    return Boolean(matchingOutlet);
                })
            );
        } else {
            newFilteredSchedules = schedules;
        };
        setFilteredSchedules(newFilteredSchedules);
    };
};

export const fetchOrgSchedules = async (orgIDs, setSchedules, setSchedulesLoading, outletID = null) => {
    try {
        setSchedulesLoading(true);
        const schedulesQuery = query(
            collection(db, "schedules"),
            where("orgID", "in", orgIDs)
        );
        const schedulesSnapshot = await getDocs(schedulesQuery);

        let schedules = schedulesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        // Filter schedules by outletID if provided
        if (outletID) {
            schedules = schedules.filter((schedule) =>
                schedule.outletsSelected.includes(outletID)
            );
        };

        setSchedules(schedules);
    } catch (error) {
        console.error("Error fetching schedules: ", error);
        setSchedules([]);
    } finally {
        if (setSchedulesLoading) {
            setSchedulesLoading(false);
        };
    };
};

// Update the status of a schedule
export const updateStatus = async (scheduleID, status) => {
    try {
        const scheduleRef = doc(db, "schedules", scheduleID);
        await updateDoc(scheduleRef, {
            status: status,
        });

        console.log("Schedule status successfully updated!");
    } catch (error) {
        console.error("Error updating schedule status: ", error);
    }
};

//DEPRECATED Create a new schedule, functino called by handler
export const createSchedule = async (scheduleData) => {
    try {
        const schedule = {
            type: "Schedule",
            status: scheduleData.status,
            dateCreated: scheduleData.dateCreated || new Date().toISOString().split("T")[0],
            missionId: scheduleData.missionId || "",
            names: scheduleData.names || [],
            outletsSelected: scheduleData.outletsSelected || [],
            repeat: scheduleData.repeat || [],
            onHour: scheduleData.onHour || 0,
            onMinute: scheduleData.onMinute || 0,
            offHour: scheduleData.offHour || 0,
            offMinute: scheduleData.offMinute || 0,
            potentialEnergySavings: scheduleData.potentialEnergySavings || 0,
            potentialPriceSavings: scheduleData.potentialPriceSavings || 0,
            potentialCO2Savings: scheduleData.potentialCO2Savings || 0,
            events: scheduleData.events || [],
            lastExecutionDate: scheduleData.lastExecutionDate || "",
            orgID: scheduleData.orgID || ""
        };
        const docRef = await addDoc(collection(db, "schedules"), schedule);
        console.log("Schedule successfully created with ID:", docRef.id);
        return docRef.id;
    } catch (error) {
        console.error("Error creating schedule:", error);
        throw error;
    }
};
// used to check for overlaps when creating or editing schedules
export const hasConflict = (newSchedule, schedules) => {
    return schedules.some(schedule => {
        // Ignore schedules with the same ID as newSchedule
        if (newSchedule.id && schedule.id === newSchedule.id) {
            return false; // Skip this schedule
        };
        // Check if any outlets overlap
        const outletConflict = newSchedule.outletsSelected.some(outlet =>
            schedule.outletsSelected.includes(outlet)
        );

        // Check if any repeat days overlap
        const dayConflict = newSchedule.repeat.some(day =>
            schedule.repeat.includes(day)
        );

        // Convert time to minutes for easier comparison
        const newStart = newSchedule.onHour * 60 + newSchedule.onMinute;
        const newEnd = newSchedule.offHour * 60 + newSchedule.offMinute;
        const existingStart = schedule.onHour * 60 + schedule.onMinute;
        const existingEnd = schedule.offHour * 60 + schedule.offMinute;

        // Check if time overlaps
        const timeConflict =
            (newStart < existingEnd && newEnd > existingStart);
        return outletConflict && dayConflict && timeConflict;
    });
};
// takes in and converts form data, calls createSchedule function
export const handleCreateSchedule = async (formData, selectedOrgID, orgID, setSchedules, schedules, setScheduleError, refresh, setRefresh) => {
    const organizationID = selectedOrgID ? selectedOrgID : orgID
    if (hasConflict(formData, schedules)) {
        setScheduleError(true);
        return false
    };
    try {
        const scheduleID = await createSchedule({
            status: "New",
            dateCreated: new Date().toISOString().split("T")[0],
            missionId: formData.missionId,
            names: formData.names || [],
            outletsSelected: formData.outletsSelected || [],
            repeat: formData.repeat || [],
            onHour: formData.onHour || 0,
            onMinute: formData.onMinute || 0,
            offHour: formData.offHour || 0,
            offMinute: formData.offMinute || 0,
            potentialEnergySavings: 0,
            potentialPriceSavings: 0,
            potentialCO2Savings: 0,
            events: [],
            lastExecutionDate: "",
            orgID: organizationID
        });
        await updateStatus(scheduleID, "Accepted");
        const newSchedule = {
            id: scheduleID,
            status: "Accepted",
            dateCreated: new Date().toISOString().split("T")[0],
            missionId: formData.missionId,
            names: formData.names || [],
            outletsSelected: formData.outletsSelected || [],
            repeat: formData.repeat || [],
            onHour: formData.onHour || 0,
            onMinute: formData.onMinute || 0,
            offHour: formData.offHour || 0,
            offMinute: formData.offMinute || 0,
            potentialEnergySavings: 0,
            potentialPriceSavings: 0,
            potentialCO2Savings: 0,
            events: [],
            lastExecutionDate: "",
            orgID: organizationID
        };

        // Update the schedules
        setSchedules((prevSchedules) => [...prevSchedules, newSchedule]);
        console.log("Schedule created successfully with ID:", scheduleID);
        return true
    } catch (error) {
        console.error("Failed to create schedule:", error);
    };
};
// function to handle editing schedules
export const handleEditSubmit = async (editData, refresh, setRefresh, schedules, setScheduleError, setSchedules) => {
    if (hasConflict(editData, schedules)) {
        setScheduleError(true);
        return false
    };
    try {
        const scheduleRef = doc(db, "schedules", editData.id);
        await updateDoc(scheduleRef, {
            missionId: editData.missionId,
            offHour: editData.offHour,
            offMinute: editData.offMinute,
            onHour: editData.onHour,
            onMinute: editData.onMinute,
            outletsSelected: editData.outletsSelected,
            repeat: editData.repeat,
            status: 'Accepted',
        });
        console.log("doc updated successfully");
        setSchedules((prevSchedules) =>
            prevSchedules.map((schedule) =>
                schedule.id === editData.id
                    ? {
                        ...schedule, ...Object.keys(editData).reduce((acc, key) => {
                            // Only add the changed fields
                            if (schedule[key] !== editData[key]) {
                                acc[key] = editData[key];
                            }
                            return acc;
                        }, {})
                    }
                    : schedule
            )
        );
        setRefresh(!refresh);
        return true
    } catch (error) {
        console.log("error updating", error);
    };
};

// function to handle deleting a schedule
export const handleScheduleDeleteSubmit = async (scheduleID, refresh, setRefresh, setSchedules) => {
    try {
        const scheduleDocRef = doc(db, "schedules", scheduleID);
        await deleteDoc(scheduleDocRef);

        console.log(`Schedule with ID ${scheduleID} deleted successfully!`);
        setSchedules((prevSchedules) =>
            prevSchedules.filter((schedule) => schedule.id !== scheduleID)
        );
        setRefresh(!refresh);
    } catch (error) {
        console.log("Error deleting event:", error);
    };
};