import {
  collection,
  collectionGroup,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import db from "../firebase";

export const fetchOutlets = async (
  orgIDs,
  timezone,
  setOutlets,
  setOutletsLoading,
  setProdTypes = null
) => {
  // Access a Firestore document
  let q;
  q = query(collection(db, "outlets"), where("orgID", "in", orgIDs));

  setOutletsLoading(true);

  const querySnapshot = await getDocs(q);

  // const unsubscribe = onSnapshot(q, (querySnapshot) => {
  let outlets_arr = [];
  let productTypes_arr = [];
  let outlet_data;
  const now = new Date();
  const nowTZ = new Date(now.toLocaleString("en-US", { timeZone: timezone }));
  // Subtract 5 minutes from the current time
  // Multiply minutes by 60000 to convert minutes to milliseconds
  const fiveMinutesAgo = new Date(nowTZ.getTime() - 5 * 60000);

  if (querySnapshot.empty) {
    console.log("[fetchOutlets] no outlets in ", orgIDs);
  } else {
    querySnapshot.forEach((doc) => {
      outlet_data = doc.data();

      // wifi status logic
      let wifiStatus = "Not activated";
      if (!doc.data().lastDataUpdate) {
        wifiStatus = "Not activated";
      } else {
        // Parse the lastDataUpdate string into a Date object
        const lastUpdateDate = new Date(doc.data().lastDataUpdate);
        lastUpdateDate.toLocaleString("en-US", { timeZone: timezone });

        // Compare lastDataUpdate with five minutes ago
        if (lastUpdateDate >= fiveMinutesAgo) {
          wifiStatus = "Connected";
        } else {
          wifiStatus = "Disconnected";
        };
      };
      // status logic
      let status = "On";
      if (!doc.data().lastOnUpdate) {
        status = "On";
        // should properly set status on first schedule implementation
        if (doc.data().lastOffUpdate) {
          status = "Off";
        };
      } else {
        // Parse the lastOnUpdate string into a Date object
        const lastOnUpdate = new Date(doc.data().lastOnUpdate);
        lastOnUpdate.toLocaleString("en-US", { timeZone: timezone });
        const lastOffUpdate = new Date(doc.data().lastOffUpdate);
        lastOffUpdate.toLocaleString("en-US", { timeZone: timezone });

        // Compare lastDataUpdate with five minutes ago
        if (lastOnUpdate >= lastOffUpdate) {
          status = "On";
        } else {
          status = "Off";
        };
      };
      // outlet info
      outlet_data["wifiStatus"] = wifiStatus;
      outlet_data["status"] = status;
      outlet_data["docID"] = doc.id;
      outlets_arr.push(outlet_data);
      // product type info
      if (!doc.data().productType) {
        productTypes_arr.push("N/A");
      } else {
        productTypes_arr.push(doc.data().productType);
      };
    });
  };

  // sort by name
  outlets_arr.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    };
    if (nameA > nameB) {
      return 1;
    };
    // names must be equal
    return 0;
  });
  // NOTE: We don't seem to use this anymore, we have a separate call for product types
  if (setProdTypes) setProdTypes([...new Set(productTypes_arr)]);
  setOutlets([...outlets_arr]); // Replace the old outlets with the new ones
  localStorage.setItem("outlets", JSON.stringify(outlets_arr));
  setOutletsLoading(false);
  //});
  //return unsubscribe;
};

//called in outletPresentational
export const toggleOutletStatus = (status, orgID, outletID, outletDocID) => {
  updateDoc(doc(db, "organizations", orgID, "Outlets", outletID), {
    status: status,
  });
  updateDoc(doc(db, "outlets", outletDocID, "Status", "status"), {
    status: status,
  });
};

export const productSelectPress = (outlet) => {
  const productType = outlet.productType;
  console.log(productType);
  updateDoc(doc(db, "organizations", outlet.orgID, "Outlets", outlet.id), {
    productType: productType,
  });
  updateDoc(doc(db, "outlets", outlet.docID), { productType: productType });
};

export const submitNameEdit = async (
  orgID,
  outletID,
  outletDocID,
  outletName
) => {
  // Check if the outlet name already exists
  const outletsRef = collection(db, "outlets");
  const q = query(
    outletsRef,
    where("name", "==", outletName),
    where("orgID", "==", orgID)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    console.log(
      "The outlet name already exists. Please choose a different name."
    );
    return false; // Return false if the name exists
  };

  // Proceed to update the documents if the name does not exist
  try {
    await updateDoc(doc(db, "organizations", orgID, "Outlets", outletID), {
      name: outletName,
    });
    await updateDoc(doc(db, "outlets", outletDocID), { name: outletName });
    console.log("Outlet name updated successfully.");
    return true; // Return true if the update was successful
  } catch (error) {
    console.error("Error updating outlet name:", error);
    return false; // Return false if there was an error
  };
};

export const submitPhotoEdit = async (outletDocID) => {
  // const q = query(outletsRef, where('id', '==', outletID));
  // const querySnapshot = await getDocs(q);
  try {
    await updateDoc(doc(db, "outlets", outletDocID), { imageURl: true });
  } catch (error) {
    console.error("Error updating imageURl", error);
  };
};

export const deletePhotoEdit = async (outletDocID) => {
  // const q = query(outletsRef, where('id', '==', outletID));
  // const querySnapshot = await getDocs(q);
  try {
    await updateDoc(doc(db, "outlets", outletDocID), { imageURl: false });
  } catch (error) {
    console.error("Error updating imageURl", error);
  };
};

//not use at the moment
export const leftSavingsPress = (
  savingsDisplayIndex,
  savingsDisplayLength,
  setSavingsDisplayIndex
) => {
  let newSavingsIndex = savingsDisplayIndex - 1;
  if (newSavingsIndex < 0) {
    newSavingsIndex = savingsDisplayLength - 1;
  };
  setSavingsDisplayIndex(newSavingsIndex);
};
//not use at the moment
export const rightSavingsPress = (
  savingsDisplayIndex,
  savingsDisplayLength,
  setSavingsDisplayIndex
) => {
  let newSavingsIndex = savingsDisplayIndex + 1;
  if (newSavingsIndex >= savingsDisplayLength) {
    newSavingsIndex = 0;
  };
  setSavingsDisplayIndex(newSavingsIndex);
};
//not use at the moment
export const leftUsageDisplayPress = (
  stackedUsageDisplayIndex,
  stackedUsageDisplayLength,
  setStackedUsageDisplayIndex
) => {
  let newUsageDisplayIndex = stackedUsageDisplayIndex - 1;
  if (newUsageDisplayIndex < 0) {
    newUsageDisplayIndex = stackedUsageDisplayLength - 1;
  };
  setStackedUsageDisplayIndex(newUsageDisplayIndex);
};
//not use at the moment
export const rightUsageDisplayPress = (
  stackedUsageDisplayIndex,
  stackedUsageDisplayLength,
  setStackedUsageDisplayIndex
) => {
  let newUsageDisplayIndex = stackedUsageDisplayIndex + 1;
  if (newUsageDisplayIndex >= stackedUsageDisplayLength) {
    newUsageDisplayIndex = 0;
  };
  setStackedUsageDisplayIndex(newUsageDisplayIndex);
};

//NOTE: Replaced by functions in savings.js
// export const fetchOutletSavings = (
//   getDoc,
//   doc,
//   db,
//   organizationID,
//   year,
//   month,
//   outlets,
//   setMonthlyCO2eSavings,
//   setMonthlyCostSavings,
//   setMonthlyEnergySavings
// ) => {
//   const fetchDataForOutlet = async (outlet) => {
//     let monthSavings;
//     try {
//       monthSavings = await getDoc(
//         doc(
//           db,
//           "organizations",
//           organizationID,
//           "Outlets",
//           outlet.id,
//           "Savings",
//           year,
//           "Months",
//           month
//         )
//       );
//       if (monthSavings && monthSavings._document !== null) {
//         // Extracting the field values
//         const co2eSavings =
//           monthSavings._document.data.value.mapValue.fields.rev_co2eSavings
//             ?.doubleValue || 0;
//         const costSavings =
//           monthSavings._document.data.value.mapValue.fields.rev_costSavings
//             ?.doubleValue || 0;
//         const energySavings =
//           monthSavings._document.data.value.mapValue.fields.rev_energySavings
//             ?.doubleValue || 0;
//         return {
//           [outlet.id]: {
//             co2eSavings,
//             costSavings,
//             energySavings,
//           },
//         };
//       } else {
//         return {
//           [outlet.id]: {
//             co2eSavings: 0,
//             costSavings: 0,
//             energySavings: 0,
//           },
//         };
//       }
//     } catch (error) {
//       // console.error(`Error fetching data for outlet ${outlet.id}:`, error);
//       return {
//         [outlet.id]: {
//           co2eSavings: 0,
//           costSavings: 0,
//           energySavings: 0,
//         },
//       };
//     }
//   };
//   const fetchDataForAllOutlets = async () => {
//     const promises = outlets.map((outlet) => fetchDataForOutlet(outlet));
//     const results = await Promise.all(promises);
//     const co2eSavingsArray = [];
//     const costSavingsArray = [];
//     const energySavingsArray = [];

//     outlets.forEach((outlet, index) => {
//       co2eSavingsArray.push({
//         [outlet.id]: results[index][outlet.id].co2eSavings,
//       });
//       costSavingsArray.push({
//         [outlet.id]: results[index][outlet.id].costSavings,
//       });
//       energySavingsArray.push({
//         [outlet.id]: results[index][outlet.id].energySavings,
//       });
//     });

//     // Update state with the separate arrays
//     setMonthlyCO2eSavings(co2eSavingsArray);
//     setMonthlyCostSavings(costSavingsArray);
//     setMonthlyEnergySavings(energySavingsArray);
//   };
//   fetchDataForAllOutlets();
// };

export const fetchProductTypes = async (setAllProdTypes) => {
  try {
    // Access a Firestore document
    let querySnapshot = await getDocs(collection(db, "productTypes"));
    let productTypesSet = new Set();
    querySnapshot.forEach((doc) => {
      productTypesSet.add(doc.id);
    });
    // Convert Set back to array and add "N/A" at the beginning
    let productTypes = ["N/A", ...Array.from(productTypesSet)];
    setAllProdTypes(productTypes);
    localStorage.setItem("productTypes", productTypes);
    return productTypes;
  } catch (error) {
    console.error("Error fetching document:", error);
  };
};

export const updateOutletDetails = async (updatedOutlet) => {
  try {
    // Update the outlet in the main "outlets" collection
    await updateDoc(doc(db, "outlets", updatedOutlet.docID), {
      name: updatedOutlet.name,
      // building: updatedOutlet.building,
      productType: updatedOutlet.productType || "N/A",
      location: updatedOutlet.location || "N/A",
      // accessRequirements: updatedOutlet.accessRequirements,
    });

    // Update the status in the "Status" subcollection inside the "outlets" collection
    await updateDoc(
      doc(db, "outlets", updatedOutlet.docID, "Status", "status"),
      {
        status: updatedOutlet.status, // Update the status field
      }
    );

    console.log("Outlet details updated successfully.");
    return true;
  } catch (error) {
    console.error("Error updating outlet details:", error);
    return false;
  };
};

export async function deleteOutlets(orgID,) {
  try {
    const q = query(collection(db, 'outlets'), where('orgID', '==', orgID));
    const querySnapshot = await getDocs(q);
    for (const docSnapshot of querySnapshot.docs) {
      // delete outlet doc
      await deleteDoc(doc(db, 'outlets', docSnapshot.id));
    };
    console.log('outlet delete complete');
  } catch (error) {
    console.error('error moving outlets: ', error);
  };
};

// Filter function used in OutletList
export const filterOutlets = (outlets, filterType, filter) => {
  return outlets.sort((a, b) => {
    const nameA = a.name;
    const nameB = b.name;
    const wifiStatusA = a.wifiStatus;
    const wifiStatusB = b.wifiStatus;
    const usageA = a.dailyUsage;
    const usageB = b.dailyUsage;
    const statusA = a.status;
    const statusB = b.status;
    const savingsA = a.costSavings;
    const savingsB = b.costSavings;
    if (filterType === "name") {
      if (filter) {
        if (nameA < nameB) {
          return -1;
        };
        if (nameA > nameB) {
          return 1;
        };
      } else {
        if (nameA < nameB) {
          return 1;
        };
        if (nameA > nameB) {
          return -1;
        };
      };
    } else if (filterType === "wifiStatus") {
      if (filter) {
        if (wifiStatusA < wifiStatusB) {
          return -1;
        };
        if (wifiStatusA > wifiStatusB) {
          return 1;
        };
      } else {
        if (wifiStatusA < wifiStatusB) {
          return 1;
        };
        if (wifiStatusA > wifiStatusB) {
          return -1;
        };
      };
    } else if (filterType === "usage") {
      if (filter) {
        if (usageA < usageB) {
          return 1;
        };
        if (usageA > usageB) {
          return -1;
        };
      } else {
        if (usageA < usageB) {
          return -1;
        };
        if (usageA > usageB) {
          return 1;
        };
      };
    } else if (filterType === "status") {
      if (filter) {
        if (statusA < statusB) {
          return -1;
        };
        if (statusA > statusB) {
          return 1;
        };
      } else {
        if (statusA < statusB) {
          return 1;
        };
        if (statusA > statusB) {
          return -1;
        };
      };
    } else if (filterType === "savings") {
      if (filter) {
        if (savingsA < savingsB) {
          return 1;
        };
        if (savingsA > savingsB) {
          return -1;
        };
      } else {
        if (savingsA < savingsB) {
          return -1;
        };
        if (savingsA > savingsB) {
          return 1;
        };
      };
    };
    return 0;
  });
};

export const updateArray2 = (array1, array2) => {
  const updatedArray2 = array2.map((item2) => {
    const match = array1.find((item1) => {
      return item1?.devices?.includes(item2.device);
    });
    if (match) {
      return { ...item2, orgName: match.name };
    };
    return item2;
  });
  return updatedArray2;
};
// used as part of the filter in outletList
export const adjustOutlets = async (
  timezone,
  wattHours,
  savings,
  outlets,
  orgData,
  setFilteredOutlets,
  filter,
  setFilterLoading,
) => {
  setFilterLoading(true);
  let today = new Date().toLocaleDateString("en-US", {
    timeZone: timezone || "UTC",
  });
  const [month, day, year] = today.split("/");
  today = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  const filteredWattHours = wattHours.filter((obj) => obj.date === today);
  const sumWattHours = filteredWattHours.map((obj) => ({
    ...obj,
    dailyUsage: obj.hourlyWattHour.reduce(
      (acc, val) => (val > 0 ? acc + val : acc),
      0
    ),
  }));
  const idToDailyUsageMap = new Map(
    sumWattHours.map((obj) => [obj.outletID, obj.dailyUsage])
  );

  const sumValuesById = (array) => {
    let result = [];
    if (array.length > 0) {
      result = array.reduce((acc, { outletID, costSavings }) => {
        if (acc[outletID]) {
          acc[outletID].costSavings += costSavings;
        } else {
          acc[outletID] = { outletID, costSavings };
        };
        return acc;
      }, {});
    };
    return Object.values(result);
  };

  const sumSavings = sumValuesById(savings);
  const idToSavingsMap = new Map(
    sumSavings.map((obj) => [obj.outletID, obj.costSavings])
  );

  if (outlets) {
    if (outlets.length > 0) {
      for (const obj of outlets) {
        if (idToDailyUsageMap.has(obj.id)) {
          obj.dailyUsage = idToDailyUsageMap.get(obj.id) / 1000;
        };
        if (!obj.dailyUsage) {
          obj.dailyUsage = 0;
        };
        if (idToSavingsMap.has(obj.id)) {
          obj.costSavings = idToSavingsMap.get(obj.id);
        };
        if (!obj.costSavings) {
          obj.costSavings = 0;
        };
      };
      outlets = updateArray2(orgData, outlets);
      setFilteredOutlets(filterOutlets(outlets, "savings", filter));
    } else {
      setFilteredOutlets([]);
    };
  };
  setFilterLoading(false);
};