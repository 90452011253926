import React, { useState, useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "./store/index.ts";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "./pages/login";
import HomeScreen from "./pages/home/index.js";
import ScheduleScreen from "./pages/schedule/index.js";
import OutletsScreen from "./pages/admin-outlets/index.js";
import InventoryScreen from "./pages/inventory/index.js";
import "./assets/fonts/Manrope-VariableFont_wght.ttf";
import "@fontsource/roboto";
import OrganizationsScreen from "./pages/adminOrganizations/index.js";
import OrganizationRegisterScreen from "./pages/registerOrganization/index.js";
import AccountInfoScreen from "./pages/accountInfo/index.js";
import AppliancesScreen from "./pages/appliances/index.js";
import OTPScreen from "./pages/otpRegister/index.js";
import OTPLoginScreen from "./pages/otpLogin/index.js";
import TestScreen from "./pages/testScreen/index.js";
import PasswordReset from "./pages/forgotPassword/index.js";
import Layout from "./layout.js";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          {/*Routes with Header*/}
          <Route element={<Layout />}>
            {/* dashboard */}
            <Route path="/" element={<HomeScreen />} />
            {/* appliance list */}
            <Route path="/appliances" element={<AppliancesScreen />} />
            {/* schedules list */}
            <Route path="/schedules" element={<ScheduleScreen />} />
            {/* admin outlets, Revert Only */}
            <Route path="/outlets" element={<OutletsScreen />} />
            {/* device inventory and assigning, Revert Only */}
            <Route path="/inventory" element={<InventoryScreen />} />
            {/* admin organizations table, Revert Only */}
            <Route path="/organizations" element={<OrganizationsScreen />} />
            {/* why do we have account details twice? /organizations/account is a duplicate*/}
            <Route path="/account" element={<AccountInfoScreen />} />
            {/* Test Screen used for demoing/testing ui components */}
            <Route path="/test" element={<TestScreen />} />
          </Route>
          {/* Routes without Header */}
          <Route path="/organizations/register" element={<OrganizationRegisterScreen />} />
          {/* otp not currently in use */}
          <Route path="/otp" element={<OTPScreen />} />
          <Route path="/login/otp" element={<OTPLoginScreen />} />
          <Route path="/password/reset" element={<PasswordReset />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
