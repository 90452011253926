import React, { useState, useEffect } from "react";
import "../../App.css"; // Import your fonts
import CalendarContainer from "../../components/calendarContainer";
import Dropdown from "../../components/dropDown";
import OrganizationPicker from "../../components/orgPicker";
import CustomLogo from "../../components/logo";
import { useOutletContext } from "react-router-dom";
import { filterSchedules } from "../../functions/schedules";
import Badge from '../../components/badge'

export default function Schedule() {
  const layoutProps = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  const [subOrgDropdownValue, setSubOrgDropdownValue] = useState('All');
  const [subOrgDropdownOpen, setSubOrgDropdownOpen] = useState(false);
  const subOrgDropdownClick = (item) => {
    setSubOrgDropdownValue(item);
  };
  const [subOrgDropdownArr, setSubOrgDropdownArr] = useState(['All'])
  const [prodTypeDropdownValue, setProdTypeDropdownValue] = useState('All');
  const [prodTypeDropdownOpen, setProdTypeDropdownOpen] = useState(false);
  const prodTypeDropdownClick = (item) => {
    setProdTypeDropdownValue(item);
  };
  const [prodTypeDropdownArr, setProdTypeDropdownArr] = useState(['All'])
  const [prodTypes, setProdTypes] = useState([]);

  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const [viewDropdownValue, setViewDropdownValue] = useState("Active Schedules");
  const [viewDropdownOpen, setViewDropdownOpen] = useState(false);

  //sets schedules display variable
  useEffect(() => {
    setFilteredSchedules(layoutProps.schedules);
    setFilteredEvents(layoutProps.events);
  }, [layoutProps.schedules, selectedDay, layoutProps.refresh, layoutProps.events]);

  // sets subOrg dropdown
  useEffect(() => {
    if (layoutProps.orgData.length > 0) {
      const extractedValues = layoutProps.orgData.map((obj) => obj.name);
      const addObjectsToState = () => {
        setSubOrgDropdownArr((prevState) => ["All", ...extractedValues]);
      };
      addObjectsToState();
    } else {
      setSubOrgDropdownArr((prevState) => ["All", layoutProps.orgData.name]);
    };
  }, [layoutProps.orgData]);
  // sets org filter for schedules
  useEffect(() => {
    filterSchedules(
      layoutProps.orgData,
      layoutProps.outlets,
      layoutProps.schedules,
      prodTypeDropdownValue,
      setFilteredSchedules,
      layoutProps.events,
      setFilteredEvents,
      setProdTypeDropdownValue,
      subOrgDropdownValue,
    );
  }, [subOrgDropdownValue, layoutProps.orgData, prodTypeDropdownValue, layoutProps.refresh]);
  // gets all product types in schedules,
  useEffect(() => {
    let allProductTypes = [];
    // filters for buildings dropdown for overview accounts
    const dropdownOrgID = layoutProps.orgData.find(
      (org) => org.name === subOrgDropdownValue
    )?.uid;

    const filteredSchedules = dropdownOrgID
      ? layoutProps.schedules.filter((schedule) => schedule.orgID === dropdownOrgID)
      : layoutProps.schedules;

    filteredSchedules.forEach((schedule) => {
      let productTypes = [];

      schedule.outletsSelected?.forEach((outlet) => {
        if (layoutProps.outlets.find((obj) => obj.id === outlet)) {
          productTypes.push(
            layoutProps.outlets.find((obj) => obj.id === outlet).productType
          );
        };
      });

      // Add unique product types from this schedule to allProductTypes
      allProductTypes = [...allProductTypes, ...new Set(productTypes)];

    });
    setProdTypes([...new Set(allProductTypes)]);
  }, [layoutProps.schedules, layoutProps.update, subOrgDropdownValue]);
  // sets product type dropdown list
  useEffect(() => {
    if (prodTypes.length > 0) {
      const addTypesToState = () => {
        setProdTypeDropdownArr((prevState) => ["All", ...prodTypes]);
      };
      addTypesToState();
    } else {
      setProdTypeDropdownArr(["All"]);
    };
  }, [prodTypes]);

  return (
    <>
      <div style={{
        display: "flex",
        backgroundColor: "#1F1F1F",
        height: "auto",
        minHeight: "100vh",
        minWidth: "1300px",
        justifyContent: "center",
        marginTop: "86px",
      }}>
        <div style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <div
              className="headlineLarge"
              style={{
                width: "350px",
                height: "auto",
                display: "block",
                alignSelf: "start",
                marginTop: "20px",
                color: "#FFFFFF",
              }}
            >
              Schedule
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
            }}>
              <div style={{
                marginRight: "16px",
                display: "flex",
                flexDirection: "row",
                justifyContent: 'space-between',
                width: '650px'
              }}>
                {/* View Dropdown */}
                <div>
                  {filteredSchedules?.filter(
                    (schedule) => schedule.status === "New"
                  ).length > 0 && viewDropdownValue === 'Active Schedules' &&
                    <div style={{position:'absolute', marginTop:'17px', marginLeft:'-2.5px'}}><Badge /></div>
                  }
                  <Dropdown
                    list={["Active Schedules", "Recommended Schedules"]}
                    value={viewDropdownValue}
                    onSelect={(item) => setViewDropdownValue(item)}
                    setIsDropdownOpen={setViewDropdownOpen}
                    isDropdownOpen={viewDropdownOpen}
                    label={"Schedule type"}
                  />
                </div>
                {/* 'Appliance Type' start as all appliance types, should only display appliances that are listed in schedules */}
                <Dropdown
                  list={prodTypeDropdownArr}
                  value={prodTypeDropdownValue}
                  onSelect={prodTypeDropdownClick}
                  setIsDropdownOpen={setProdTypeDropdownOpen}
                  isDropdownOpen={prodTypeDropdownOpen}
                  label={"Appliances"}
                />
                {layoutProps.orgID == "LZqI3R6MInUuwtgtROPK" && (
                  <OrganizationPicker
                    layoutProps={layoutProps}
                    label={true}
                  />
                )}
                {/* 'Buildings' i.e. suborgs, default will be to show all suborg schedules */}
                {subOrgDropdownArr.length > 2 && (
                  <Dropdown
                    list={subOrgDropdownArr}
                    value={subOrgDropdownValue}
                    onSelect={subOrgDropdownClick}
                    setIsDropdownOpen={setSubOrgDropdownOpen}
                    isDropdownOpen={subOrgDropdownOpen}
                    label={"Buildings"}
                  />
                )}
              </div>
            </div>
          </div>
          {viewDropdownValue === "Active Schedules" ? (
            <div style={{ marginTop: "30px", marginBottom: "20px" }}>
              <CalendarContainer
                layoutProps={layoutProps}
                filteredEvents={filteredEvents}
                filteredSchedules={filteredSchedules}
                subOrgDropdownValue={subOrgDropdownValue}
                filter="Accepted"
              />
            </div>
          ) : (
            <div style={{ marginTop: "30px", marginBottom: "20px" }}>
              <CalendarContainer
                layoutProps={layoutProps}
                filteredEvents={filteredEvents}
                filteredSchedules={filteredSchedules?.filter(
                  (schedule) => schedule.status === "New"
                )}
                subOrgDropdownValue={subOrgDropdownValue}
                filter="New"
              />
            </div>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <CustomLogo name="horizontal-white" />
          </div>
        </div>
      </div>
    </>
  );
}
