import React from "react"
import { neutral300, neutral400, primary500 } from "../../lib/rebrandColors/colors";
import ScheduleSavingsGraphic from "../scheduleSavingsGraphic";

export default function MultiScheduleSavingsGraphic({
    projectedCostObject,
    projectedSavingsObject,
    width,
    height,
    name,
}) {

    const styles = {
        container: {
            display: "flex",
            flexWrap: "wrap", // Allows wrapping items into multiple rows
            justifyContent: "space-between", // Ensures spacing between columns
            maxHeight: `${height}px`,
            overflowY: 'scroll',
            width: `${width}px`,
        },
        item: {
            width: Object.keys(projectedCostObject)?.length > 1 ? "48%" : '100%', // Ensures two items per row when there are more than 1
            marginBottom: "10px",
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center'
        },
        title: {
            marginBottom: '2px',
            fontFamily: "IBM Plex Sans",
            fontSize: '12px',
            display: 'flex',
            alignSelf: 'flex-start',
            marginLeft: '10px'
        }
    };

    return (
        <div style={styles.container}>
            {Object.keys(projectedCostObject).map((key) => {
                // matches any string that starts with `name, `, followed by any characters. The (.+)$captures everything after `name, `
                const regex = new RegExp(`^${name}, (.+)$`);
                const match = key.match(regex);
                // If there's a match, extract the "rest of key"; otherwise, use the full key
                const displayKey = match ? match[1] : key;
                return (
                    <div style={styles.item} key={key}>
                        <div style={styles.title}>
                            {displayKey} {(projectedSavingsObject[key] / projectedCostObject[key]) * 100 < 15 ?
                                <span style={{ color: primary500, paddingLeft: '5px' }}> ${projectedSavingsObject[key].toFixed(2)} Savings</span>
                                :
                                ``
                            }
                            {((projectedCostObject[key] - projectedSavingsObject[key]) / projectedCostObject[key]) * 100 < 15 ?
                                <span style={{ color: neutral400, paddingLeft: '5px' }}> ${(projectedCostObject[key] - projectedSavingsObject[key]).toFixed(2)} Residual Cost</span>
                                :
                                ``
                            }
                            {/* <span style={{color: primary500 }}> ${projectedCostObject[key].toFixed(2)}</span> */}
                        </div>
                        <ScheduleSavingsGraphic
                            key={key}
                            projectedCost={projectedCostObject[key]}
                            projectedSavings={projectedSavingsObject[key]}
                            width={325}
                            height={30}
                        />
                    </div>
                );
            })}
        </div>
    );
};