import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Switch,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../firebase";
import { submitPhotoEdit, deletePhotoEdit, updateOutletDetails, fetchProductTypes } from "../../functions/outlets";
import imageCompression from "browser-image-compression";
import IconButton from "../iconButton";
import { neutral800, primary500, white } from "../../lib/rebrandColors/colors";
import CustomIcon from "../icon";
import Dropdown from "../dropDown";
import ApplianceEditScreen from "../applianceEditDetails";

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .Mui-checked": {
    color: "#E50914", // Red color for the switch
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#E50914", // Red background when "On"
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#D5D5D5", // Gray background when "Off"
  },
}));

const ApplianceDetailsCard = ({
  outlet,
  editing = false,
  prodTypes = [],
  onFieldChange = null,
  orgData,
  wattHours,
  averageUsage,
  savings,
  setCurrentOutlet,
}) => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [photoUrl, setPhotoUrl] = useState(outlet?.photo || "");
  const [productTypes, setProductTypes] = useState([]);
  const [onDropdownOpen, setOnDropdownOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const openModal = () => setIsEditModalOpen(true);
  const closeModal = () => setIsEditModalOpen(false);
  // needs to be switched to a "modal"
  const onEdit = () => {
    openModal();
  };
  
  
  const [editableDetails, setEditableDetails] = useState({
    name: outlet?.name || "N/A",
    organization: orgData[0]?.name || "N/A",
    productType: outlet?.productType || "N/A",
    location: outlet?.location || "N/A",
    accessRequirements: outlet?.accessRequirements || "N/A",
    id: outlet?.id || "N/A",
    status: outlet?.status || "Off",
    lastDataUpdate: outlet?.lastDataUpdate || '',
  });

  useEffect(() => {
    setEditableDetails({
      name: outlet?.name || "N/A",
      organization: orgData[0]?.name || "N/A",
      productType: outlet?.productType || "N/A",
      location: outlet?.location || "N/A",
      accessRequirements: outlet?.accessRequirements || "N/A",
      id: outlet?.id || "N/A",
      status: outlet?.status || "Off",
      lastDataUpdate: outlet?.lastDataUpdate || '',
    });
  }, [outlet]);

  const handleInputChange = (field, value) => {
    const updatedDetails = { ...editableDetails, [field]: value };
    setEditableDetails(updatedDetails);
    if (onFieldChange) {
      onFieldChange(field, value); // Call parent handler if provided
    };
  };

  const onDropdownClick = (item) => {
    handleInputChange("productType", item)
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    if (file) {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      });
      const storageRef = ref(storage, fileName);
      try {
        const snapshot = await uploadBytes(storageRef, compressedFile);
        const downloadUrl = await getDownloadURL(snapshot.ref);
        setPhotoUrl(downloadUrl);
        await submitPhotoEdit(outlet.docID);
        handleInputChange("photo", downloadUrl);
      } catch (error) {
        console.error("[ApplianceDetailsCard] Error uploading file:", error);
      };
    };
  };

  const changeApplianceStatus = async (newStatus) => {
    try {
      const updatedOutlet = { ...outlet, "status": newStatus }

      await updateOutletDetails(updatedOutlet);

      // Update the local state
      handleInputChange("status", newStatus);

      console.log("[ApplianceDetailsCard] Outlet status updated successfully:", newStatus);
    } catch (error) {
      console.error("Failed to update outlet status:", error);
    };
  };

  const handleDeletePhoto = async () => {
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    const storageRef = ref(storage, fileName);
    try {
      await deletePhotoEdit(outlet.docID);
      await deleteObject(storageRef);
      setPhotoUrl("");
      handleInputChange("photo", "");
    } catch (error) {
      console.error("Error deleting file:", error);
    };
  };

  const details = [
    {
      label: "Outlet ID",
      value: editableDetails.id,
      field: "id",
      disabled: true,
    },
    {
      label: "Organization",
      value: editableDetails.organization,
      field: "organization",
      disabled: true,
    },
    { label: "Name", value: editableDetails.name, field: "name", edit: 'Text' },
    { label: "Product Type", value: editableDetails.productType, field: "productType", edit: 'Dropdown' },
    { label: "Last Data Update", value: editableDetails.lastDataUpdate.slice(0, 16), field: "lastDataUpdate", disabled: true },
    // {
    //   label: "Access Requirements",
    //   value: editableDetails.accessRequirements,
    //   field: "accessRequirements",
    // },
  ];

  useEffect(() => {
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    const fetchImageUrl = async () => {
      try {
        const fileRef = ref(storage, fileName);
        const url = await getDownloadURL(fileRef);
        setPhotoUrl(url);
      } catch (error) {
        console.error("Error fetching image URL:", error);
      };
    };
    if (outlet.imageURl == true) {
      fetchImageUrl();
    };
    fetchProductTypes(setProductTypes);
  }, []);

  return (
    <Box sx={{
      width: "520px",
      //height: "680px",
      padding: "20px",
      borderRadius: "16px",
      border: "1px solid #E0E0E0",
      backgroundColor: "#F9F9F9",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    }}>
      {/* Edit Button */}
      {!editing && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <IconButton
            onClick={onEdit}
            tone={null}
            buttonType=""
            size={30}
            icon={<CustomIcon name="pencil-edit" color={primary500} />}
          />
        </Box>
      )}
      {/* Circle Image with Overlay Edit Icon */}
      <Box
        sx={{
          position: "relative",
          borderRadius: "50%",
          width: "150px",
          height: "150px",
          overflow: "hidden",
          marginBottom: "20px",
          cursor: editing ? "pointer" : "default",
          border: editing ? `2px dashed ${neutral800}` : "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: photoUrl ? "transparent" : "#E0E0E0",
        }}
        onClick={editing ? handleUploadClick : undefined}
      >
        {photoUrl ? (
          <img
            src={photoUrl}
            alt="Appliance"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <Typography
            sx={{
              color: "#666",
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            No photo available
          </Typography>
        )}
        {editing && (
          <>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
                padding: "10px",
              }}
            >
              <CustomIcon name="cloud-upload" color={white} size={24} />
            </Box>
          </>
        )}
      </Box>
      {editing && photoUrl && (
        <Typography
          sx={{ cursor: "pointer", color: "#E50914", marginBottom: "10px" }}
          onClick={handleDeletePhoto}
        >
          Delete Photo
        </Typography>
      )}
      {/* Details List */}
      <Box sx={{ width: "100%" }}>
        {details.map((detail, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0",
              borderBottom: index < 4 ? "1px solid #CCC" : "none",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#666" }}
            >
              {detail.label}
            </Typography>
            {editing && !detail.disabled && detail.edit == 'Text' ? (
              <TextField
                value={detail.value}
                size="small"
                onChange={(e) =>
                  handleInputChange(detail.label.toLowerCase(), e.target.value)
                }
              />
            ) :
              editing && !detail.disabled && detail.edit == 'Dropdown' ? (
                <Dropdown
                  list={productTypes}
                  value={detail.value}
                  onSelect={onDropdownClick}
                  setIsDropdownOpen={setOnDropdownOpen}
                  isDropdownOpen={onDropdownOpen}
                  size={200}
                  background="dark"
                />
                // <Dropdown
                //   value={detail.value}
                //   size="small"
                //   onChange={(e) =>
                //     handleInputChange(detail.label.toLowerCase(), e.target.value)
                //   }
                // />
              )
                :
                (
                  <Typography sx={{ fontSize: "14px", color: "#333" }}>
                    {detail.value}
                  </Typography>
                )}
          </Box>
        ))}
        {/* status switch */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0",
            borderTop: "1px solid #CCC",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              color: "#666",
              flexBasis: "40%",
            }}
          >
            Status
          </Typography>
          {/* {editing ? ( */}
          <RedSwitch
            checked={editableDetails.status === "On"}
            onChange={async () => {
              const newStatus = editableDetails.status === "On" ? "Off" : "On";
              await changeApplianceStatus(newStatus);
            }}
            color="primary"
          />
          {/* ) : ( */}
          {/* <Typography
              sx={{
                fontSize: "14px",
                color: "#333",
              }}
            >
              {editableDetails.status}
            </Typography> */}
          {/* )} */}
        </Box>
      </Box>
      <ApplianceEditScreen
        outlet={outlet}
        prodTypes={prodTypes}
        orgData={orgData}
        wattHours={wattHours}
        averageUsage={averageUsage}
        savings={savings}
        open={isEditModalOpen}
        handleClose={closeModal}
        setCurrentOutlet={setCurrentOutlet}
      />
    </Box>
  );
};

export default ApplianceDetailsCard;
