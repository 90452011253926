import React from "react"
import { neutral300, primary500 } from "../../lib/rebrandColors/colors";

export default function ScheduleSavingsGraphic({
    projectedCost,
    projectedSavings,
    width,
    height,
}) {

    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            {/* Back Rectangle */}
            <div style={{
                width: `${width}px`,
                height: `${height}px`,
                backgroundColor: primary500,
                borderRadius: `${height / 2}px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end", // Aligns number to the right
                paddingRight: "10px", // Adds spacing for the number
                color: "white",
                fontSize: "16px",
                position: "relative",
                fontFamily: "IBM Plex Sans",
            }}>
                {(projectedSavings / projectedCost) * 100 > 15 ? `$${(projectedSavings).toFixed(2)}`:``}
            </div>
            {/* Front Rectangle */}
            <div style={{
                width: `${((projectedCost - projectedSavings) / projectedCost) * width}px`,
                height: `${height}px`,
                backgroundColor: neutral300,
                borderRadius: `${height / 2}px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start", // Aligns number to the right
                paddingLeft: "15px", // Adds spacing for the number
                // color: "white",
                fontSize: "16px",
                position: "absolute",
                fontFamily: "IBM Plex Sans",
                top: "0",
                left: "0", // Aligns with the left side of the back shape
            }}>
                {((projectedCost - projectedSavings) / projectedCost) * 100 > 15 ? `$${(projectedCost - projectedSavings).toFixed(2)}` : ``}
                {/* ${(projectedCost - projectedSavings).toFixed(2)} */}
            </div>
        </div>
    );
};