import CustomIcon from "../icon";
import '../../App.css'; // Import your fonts

export default function Chip({
    label = "Label",
    size = "small",
    alert = false,
    iconName,
    icon,
    iconPosition = "right"
}) {
    const sizeOptions = {
        small: { maxChars: 28, fontSize: 13, adjustment: .6 },
        medium: { maxChars: 40, fontSize: 18, adjustment: .7 },
        large: { maxChars: 60, fontSize: 20, adjustment: .8 },
    };
    let { maxChars, fontSize, adjustment } = sizeOptions[size] || sizeOptions['small'];
    if (icon || iconName) {
        maxChars = Math.floor(maxChars * adjustment);
    };
    const iconHeight = fontSize + 6;
    const limitedLabel = label.length > maxChars ? label.slice(0, maxChars) + '...' : label;

    const borderColor = alert ?
        '#FF6D2A'
        : 
        '#009C49'

    const backgroundColor = alert ?
        '#FEF8F2'
        : 
        '#DCFFEC'

    const iconLineColor = alert ?
    '#FF6D2A'
    : 
    '#00A54C'

    const customButtonStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '1px',
        backgroundColor: backgroundColor,
        borderStyle: 'solid',
        borderColor: borderColor,
        width: size === 'large' ? '500px' : size === 'medium' ? '300px' : '132px',
        padding: '8px 16px 8px 16px',
        borderRadius: '24px',
        height: '20px'
        //opacity: '0px',
    };
    const buttonLabelStyle = {
        color: iconLineColor,
        fontSize: fontSize,
        marginLeft: iconPosition === 'left' ? '10px' : 0,
        marginRight: iconPosition === 'right' ? '10px' : 0,
        lineHeight:'15px',
        textAlign:'center'
    };

    return (
        <div
            style={customButtonStyle}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                {icon ? iconPosition === 'left' &&
                    icon
                    : null
                }
                {iconName ? iconPosition === 'left' &&
                    <CustomIcon
                        name={iconName}
                        size={iconHeight}
                        color={iconLineColor}
                    />
                    : null
                }
                {label ?
                    <div className = "buttonLargeBold"
                        style={buttonLabelStyle}
                    >
                        {limitedLabel}
                    </div>
                    : null
                }
                {icon ? iconPosition === 'right' &&
                    icon
                    : null
                }
                {iconName ? iconPosition === 'right' &&
                    <CustomIcon
                        name={iconName}
                        size={iconHeight}
                        color={iconLineColor}
                    />
                    : null
                }
            </div>
        </div>
    );
};