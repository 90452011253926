import React, { useState, useEffect } from "react";
import {
  black,
  primary500,
  grey600,
} from "../../lib/rebrandColors/colors";
import { useLocation, useNavigate } from "react-router-dom";
import "../../App.css"; // Import your fonts
import TextButton from "../textButton";
import { CircularProgress, } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";
import Chip from "../chip";
import ApplianceTypeList from "../applianceTypeList";
import { adjustOutlets } from "../../functions/outlets";
import CustomIcon from "../icon";
import ApplianceDetailsScreen from "../applianceDetails";
import OutletCard from "../outletCard";

export default function OutletList({
  layoutProps,
  width,
  height
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const [filteredOutlets, setFilteredOutlets] = useState(layoutProps.outlets);
  const [currentOutlet, setCurrentOutlet] = useState({});
  const [index, setIndex] = useState();
  const path = location.pathname;
  const [selectedTab, setSelectedTab] = useState(location.state?.updateTab ? 1 : 0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // convert to a "modal", should be whole screen, display appliance details
  const handleRowClick = (outlet, i) => {
    setIndex(i);
    setCurrentOutlet(outlet);
    setIsModalOpen(true);
  };

  useEffect(() => {
    adjustOutlets(
      layoutProps.timezone,
      layoutProps.wattHours,
      layoutProps.savings,
      layoutProps.outlets,
      layoutProps.orgData,
      setFilteredOutlets,
      filter,
      setFilterLoading,
    );
  }, [layoutProps.outletsLoading, layoutProps.orgData, layoutProps.timezone, layoutProps.outlets]);

  return (
    <>
      <div style={{
        margin: ".5%",
        minWidth: "500px",
        borderRadius: "24px",
        minHeight: "824px",
      }}>
        {/* header for dashboard */}
        {location.pathname === '/' &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            marginTop: '30px',
          }}>
            <CustomIcon
              name="plug"
              color={black}
              size={30}
            />
            <div
              className="bodyLarge"
              style={{
                marginLeft: '8px',
              }}
            >
              Appliances
            </div>
          </div>
        }
        {/* tab bar */}
        <div style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: '20px',
          marginBottom: '20px',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: width ? width : "480px",
          }}>
            <TextButton
              label='Appliance type'
              underlined={true}
              onPress={() => handleTabChange(0)}
              fill={selectedTab == 0 && primary500}
            />
            <div style={{ marginLeft: '10px' }}>
              <TextButton
                label='Individual appliances'
                underlined={true}
                onPress={() => handleTabChange(1)}
                fill={selectedTab == 1 && primary500}
              />
            </div>
          </div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          height: height,
          overflowY: "scroll",
          alignItems: "center",
          width: "100%",
        }}>
          {filterLoading ? (
            <div style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
            }}>
              <CircularProgress />
            </div>
          ) : selectedTab === 1 ? (
            // NOTE: Switch this to flatList
            filteredOutlets.length > 0 ? (
              filteredOutlets.map((outlet, i) => {
                return (
                  <OutletCard
                    key={`${outlet.id}${i}`}
                    outlet={outlet}
                    handleRowClick={handleRowClick}
                    i={i}
                    width={width}
                  />
                );
              })
            ) : (
              <div
                className="headlineMedium"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "50px",
                  color: "#999999",
                }}
              >
                No appliances available
              </div>
            )
          ) : (
            <ApplianceTypeList layoutProps={layoutProps} height={height} width={width} />
          )}
        </div>
      </div>
      {/* modal */}
      <ApplianceDetailsScreen
        outlet={currentOutlet}
        setCurrentOutlet={setCurrentOutlet}
        open={isModalOpen}
        handleClose={closeModal}
      />
    </>
  );
}