import React, { useState, useEffect } from "react";
import "../../App.css"; // Import your fonts
import DailyScheduleView from "../../components/dailyScheduleView";
import TaskList from "../../components/tasksList";
import OutletList from "../../components/outletList";
import OrganizationPicker from "../../components/orgPicker";
import CustomLogo from "../../components/logo";
import CustomIcon from "../../components/icon";
import { useOutletContext } from "react-router-dom";
import DailyUsageChart from "../../components/charts/dailyUsageChart";
import SavingsDisplay from "../../components/savingsDisplay";
import BuildingsContainer from "../../components/buildingsContainer"; //not currently implemented
import { black } from "../../lib/rebrandColors/colors";
import IconButton from "../../components/iconButton";
import { EastTwoTone } from "@mui/icons-material";


export default function Dashboard() {
  // variables from layout
  const layoutProps = useOutletContext();
  const [scheduleHide, setScheduleHide] = useState(false);
  const [taskHide, setTaskHide] = useState(false);
  const [usageHide, setUsageHide] = useState(false);
  const [applianceHide, setApplianceHide] = useState(false);

  return (
    <>
      <div style={{
        display: "flex",
        backgroundColor: "#1F1F1F",
        height: "auto",
        minHeight: "100vh",
        minWidth: "1300px",
        justifyContent: "center",
        marginTop: "86px",
      }}>
        <div style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <div
              className="headlineLarge"
              style={{
                width: "350px",
                height: "auto",
                display: "block",
                alignSelf: "start",
                marginTop: "10px",
                color: "#FFFFFF",
              }}
            >
              Dashboard
            </div>
            {(layoutProps.isOverviewAccount === "true" ||
              layoutProps.orgID === "LZqI3R6MInUuwtgtROPK") && (
                <OrganizationPicker
                  layoutProps={layoutProps}
                  label={true}
                />
              )}
          </div>
          <div style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            marginBottom: "30px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
            }}>
              {/* Schedule Block */}
              <div style={{
                width: "560px",
                display: "flex",
                borderRadius: "24px",
                // minHeight: "693px",
                backgroundColor: "#FAFAFA",
                maxHeight: "693px",
                overflowY: "hidden",
                marginRight: "16px",
                justifyContent: "center",
                marginBottom: "15px",
                flexDirection: 'column'
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingTop: '10px',
                  paddingBottom: !scheduleHide ? '0px' : '10px',
                  marginBottom: !scheduleHide ? '-70px' : '0px',
                  paddingRight: '20px',
                  justifyContent: 'space-between',
                }}>
                  <div style={{ zIndex: 1, }}>
                    <IconButton
                      size={30}
                      iconName={scheduleHide ? 'arrow-down-1' : 'arrow-up-1'}
                      buttonType="tonal"
                      tone="light"
                      onClick={() => setScheduleHide(!scheduleHide)}
                    />
                  </div>
                  {scheduleHide &&
                    <div style={{ marginLeft: '40px' }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <CustomIcon
                          name="calendar"
                          color={black}
                          size={30}
                        />
                        <div
                          className="bodyLarge"
                          style={{
                            marginLeft: '8px',
                          }}
                        >
                          Schedule
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div>
                  {!scheduleHide &&
                    <div style={{ width: "95%" }}>
                      <DailyScheduleView
                        layoutProps={layoutProps}
                        width={layoutProps.windowDimensions.width}
                        height={"615px"}
                      />
                    </div>
                  }
                </div>
              </div>
              {/* Task Block */}
              <div style={{
                width: "560px",
                borderRadius: "24px",
                // minHeight: "339px",
                backgroundColor: "#FAFAFA",
                maxHeight: "339px",
                overflowY: "hidden",
                marginBottom: "15px",
                display: 'flex',
                flexDirection: 'column',
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingTop: '10px',
                  paddingBottom: !taskHide ? '0px' : '10px',
                  marginBottom: !taskHide ? '-70px' : '0px',
                  paddingRight: '20px',
                  justifyContent: 'space-between',
                }}>
                  <div style={{ zIndex: 1, }}>
                    <IconButton
                      size={30}
                      iconName={taskHide ? 'arrow-down-1' : 'arrow-up-1'}
                      buttonType="tonal"
                      tone="light"
                      onClick={() => setTaskHide(!taskHide)}
                    />
                  </div>
                  {taskHide &&
                    <div style={{ marginLeft: '40px' }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <CustomIcon
                          name="task"
                          color={black}
                          size={30}
                        />
                        <div
                          className="bodyLarge"
                          style={{
                            marginLeft: '8px',
                          }}
                        >
                          Tasks
                        </div>
                      </div>
                    </div>
                  }
                </div>
                {!taskHide &&
                  <TaskList
                    layoutProps={layoutProps}
                    height={"255px"}
                  />
                }
              </div>
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
            }}>
              {/* usage block */}
              <div style={{
                width: "560px",
                borderRadius: "24px",
                // minHeight: "500px",
                backgroundColor: "#FAFAFA",
                maxHeight: "620px",
                overflowY: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
                flexDirection: 'column',
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingTop: '10px',
                  paddingBottom: !usageHide ? '0px' : '10px',
                  marginBottom: !usageHide ? '-70px' : '0px',
                  paddingRight: '20px',
                  justifyContent: 'space-between',
                  width: '100%'
                }}>
                  <div style={{ zIndex: 1, }}>
                    <IconButton
                      size={30}
                      iconName={usageHide ? 'arrow-down-1' : 'arrow-up-1'}
                      buttonType="tonal"
                      tone="light"
                      onClick={() => setUsageHide(!usageHide)}
                    />
                  </div>
                  {usageHide &&
                    <div style={{ marginLeft: '60px', }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <CustomIcon
                          name="connect"
                          color={black}
                          size={30}
                        />
                        <div
                          className="bodyLarge"
                          style={{
                            marginLeft: '8px',
                          }}
                        >
                          Today's Usage
                        </div>
                      </div>
                    </div>
                  }
                </div>
                {!usageHide &&
                  <div>
                    <DailyUsageChart
                      layoutProps={layoutProps}
                    />
                    <div style={{ marginTop: '50px', marginBottom: '20px', width: '100%' }}>
                      <SavingsDisplay
                        layoutProps={layoutProps}
                      />
                    </div>
                  </div>
                }
              </div>
              <div style={{
                width: "560px",
                borderRadius: "24px",
                // minHeight: "400px",
                backgroundColor: "#FAFAFA",
                maxHeight: "409px",
                overflowY: "hidden",
                marginBottom: "15px",
                display: 'flex',
                flexDirection: 'column',
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingTop: '10px',
                  paddingBottom: !applianceHide ? '0px' : '10px',
                  marginBottom: !applianceHide ? '-60px' : '0px',
                  paddingRight: '20px',
                  justifyContent: 'space-between',
                }}>
                  <div style={{ zIndex: 1, }}>
                    <IconButton
                      size={30}
                      iconName={setApplianceHide ? 'arrow-down-1' : 'arrow-up-1'}
                      buttonType="tonal"
                      tone="light"
                      onClick={() => setApplianceHide(!applianceHide)}
                    />
                  </div>
                  {applianceHide &&
                    <div style={{ marginLeft: '20px' }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <CustomIcon
                          name="plug"
                          color={black}
                          size={30}
                        />
                        <div
                          className="bodyLarge"
                          style={{
                            marginLeft: '8px',
                          }}
                        >
                          Appliances
                        </div>
                      </div>
                    </div>
                  }
                </div>
                {!applianceHide &&
                  <OutletList
                    layoutProps={layoutProps}
                    height={"255px"}
                  />
                }
              </div>
              {/*UNCOMMENT THE BELOW WHEN READY FOR BUILDINGS CONTAINER*/}
              {/* {(isOverviewAccount === "true" ||
                orgID === "LZqI3R6MInUuwtgtROPK") && (
                <div
                  style={{
                    width: "560px",
                    borderRadius: "24px",
                    minHeight: "339px",
                    backgroundColor: "#FAFAFA",
                    // maxHeight: "349px",
                    overflowY: "hidden",
                    // scrollbarWidth:"none"
                  }}
                >
                  <BuildingsContainer orgID={orgID} selectedOrgID={selectedOrgID} />
                </div>
              )} */}
            </div>
          </div>
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}>
            <CustomLogo name="horizontal-white" />
          </div>
        </div>
      </div>
    </>
  );
};
