import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../../firebase";
import { signOut } from "@firebase/auth";
import { fetchGlobalSavings } from "../../../functions/savings"
import TextButton from "../../../components/textButton";
import TextField from "../../../components/textField";
import CustomButton from "../../../components/customButton";
import CustomLogo from "../../../components/logo";
import { white } from "../../../lib/rebrandColors/colors";
import '../../../App.css'; // Import your fonts

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export default function Login({
  onLogin,
  open,
  handleClose,
  alert,
  setAlert,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [user, waiting, error] = useAuthState(auth);
  const location = useLocation();
  const navigate = useNavigate();

  const [savings, setSavings] = useState({
    rev_co2eSavings: 0,
    rev_costSavings: 0,
    rev_energySavings: 0,
  });

  // fetches global savings
  useEffect(() => {
    const loadSavings = async () => {
      const fetchedSavings = await fetchGlobalSavings();
      setSavings(fetchedSavings);
    };

    loadSavings();
  }, []);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // window resize
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // signs out after registration
  useEffect(() => {
    if (user) {
      localStorage.clear();
      if (location.state?.from === "/organizations/register") {
        console.log("signing out");
        signOut(auth);
      };
      navigate("/");
    };
  }, [user]);

  const registerNewOrganization = () => {
    // Pass the current location (or page) as state
    navigate("/organizations/register", {
      state: { from: window.location.pathname },
    });
  };

  const handleEmailChange = (value) => {
    const newEmail = value;
    if (newEmail.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    };
    setEmail(newEmail);
  };

  const handlePasswordChange = (value) => {
    const newPassword = value;
    setPassword(newPassword);
  };

  const text = "Plug in,\nstart saving.";

  const handleLogoClick = () => {
    window.location.href = 'https://reverttechnologies.com/';
  };

  return (
    <>
      <div style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#1F1F1F',
        height: 'auto',
        minHeight: '100vh',
        // width: '100%',
        minWidth: '1200px',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div
          style={{
            display: "flex",
            width: '50%',
            minWidth: '550px',
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: '30px',//windowDimensions.height < 415 ? '30px' : '8vh',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignSelf: 'center',
            }}
          >
            <div
              style={{
                width: "160px",
                height: "auto",
                display: "block",
                alignSelf: 'start',
                // marginLeft: '18%',
                marginTop: '10px',
                cursor: 'pointer',
              }}
              onClick={handleLogoClick}
            >
              <CustomLogo
                name="horizontal-negative"
              />
            </div>
            <div
              className="displayLarge"
              style={{
                color: white,
                // width: '80%',
                fontWeight: 700,
                marginTop: '50px',
                fontSize: "48px",
                textAlign: "left",
                alignSelf: 'start',
              }}
            >
              Welcome back
            </div>
            <div
              className="bodyLarge"
              style={{
                color: '#B7B7B7',
                fontSize: "16px",
                // width: '80%',
                textAlign: "left",
                alignSelf: 'start',
              }}
            >
              Enter your email and password to access your account
            </div>
            <div style={{
              marginTop: '40px',
              alignSelf: 'start',
              // marginLeft: '5%',
            }}>
              <TextField
                placeholder={"Email"}
                size={"medium"}
                onChange={handleEmailChange}
                descriptionText={open ? alert.message : ''}
                textError={open ? true : false}
              />
            </div>
            <div
              style={{
                alignSelf: 'start',
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onLogin(email, password, "email");
                };
              }}
            >
              <TextField
                placeholder={"Password"}
                size={"medium"}
                onChange={handlePasswordChange}
                visibility={true}
              />
            </div>
            <div
              style={{
                alignSelf: 'end',
                // marginLeft: '90%',
                minWidth: '120px',
              }}>
              <TextButton
                fontSize={'11px'}
                label={"Forgot password?"}
                onPress={() => navigate('/password/reset')}
              />
            </div>
            {/* {alert.show && (
              <Alert
                severity={alert.severity}
                sx={{ mt: 2, fontFamily: "Manrope" }}
              >
                {alert.message}
              </Alert>
            )} */}
            <div style={{
              alignSelf: 'start',
              marginTop: '50px',
              // marginLeft: '16.5%',
              marginBottom: '1%',
            }}>
              <CustomButton
                size={"large"}
                custFontSize={16}
                filled={true}
                disabled={isDisabled}
                label='Log in'
                iconName={'arrow-right'}
                iconPosition="right"
                onClick={() => onLogin(email, password, "email")}
              />
            </div>
            <div style={{
              marginTop: '10px',
              alignSelf: 'start',
              marginBottom: '1.5%',
            }}>
              <CustomButton
                size={"large"}
                custFontSize={16}
                filled={true}
                colored={false}
                label='Register'
                onClick={() => registerNewOrganization()}
              />
            </div>
            <div
              style={{
                minWidth: '400px',
                //marginBottom: "2.5%",
                marginTop: "0.5%",
                fontSize: '11px',
                color: '#999999',
                textAlign: 'center',
                alignSelf: 'center',
              }}
              className="labelLarge"
            >
              By clicking "Login" you agree to Revert's{" "}
              <a
                href="https://reverttechnologies.com/policies/terms-of-service"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#999999' }}
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://reverttechnologies.com/policies/privacy-policy"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#999999' }}
              >
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </div>
        <div style={{
          alignSelf: 'center',
          display: "flex",
          height: '95vh',
          minHeight: '400px',
          width: '47.5%',
          minWidth: '500px',
          flexDirection: "column",
          backgroundColor: 'white',
          borderRadius: '20px',
        }}>
          <div style={{
            display: "flex",
            flexDirection: 'column',
            marginTop: '75px',
            marginLeft: '45px'
          }}>
            <div
              className="displayLarge"
              style={{
                whiteSpace: 'pre-line',
                fontWeight: 700,
                fontSize: '80px',
                lineHeight: '104px',
                color: '#2D2D2D',
              }}
            >
              {text}
            </div>
            <div
              className='titleLarge'
              style={{
                color: '#666666',
                marginTop: '150px'
              }}
            >
              Across all customers
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              width: '95%',
              justifyContent: 'space-between',
              marginTop: '22px',
            }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className="titleLarge"
                  style={{
                    color: '#666666',
                    fontSize: '20px',
                  }}
                >
                  Energy Savings
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                  <div
                    className="displayMedium"
                    style={{
                      fontWeight: '700',
                      color: '#999999',
                    }}
                  >
                    {(savings.rev_energySavings / 1000000).toFixed(1)}
                  </div>
                  <div
                    className="titleLarge"
                    style={{
                      color: '#999999',
                      lineHeight: '70px',
                      marginLeft: '5px',
                    }}
                  >
                    MWh
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className="titleLarge"
                  style={{
                    color: '#666666',
                    fontSize: '20px',
                  }}
                >
                  Bill Savings
                </div>
                <div
                  className="displayMedium"
                  style={{
                    fontWeight: '700',
                    color: '#999999',
                  }}
                >
                  ${(savings.rev_costSavings).toFixed(0)}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className="titleLarge"
                  style={{
                    color: '#666666',
                    fontSize: '20px',
                  }}
                >
                  CO2e Savings
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                  <div
                    className="displayMedium"
                    style={{
                      fontWeight: '700',
                      color: '#999999',
                    }}
                  >
                    {(savings.rev_co2eSavings / 2000).toFixed(0)}
                  </div>
                  <div
                    className="titleLarge"
                    style={{
                      color: '#999999',
                      lineHeight: '70px',
                      marginLeft: '5px',
                    }}
                  >
                    tons
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
