import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Box, Modal, Typography } from "@mui/material";
import SavingsDisplay from "../savingsDisplay";
import DailyUsageChart from "../charts/dailyUsageChart";
import ApplianceDetailsCard from "../applianceDetailsCard";
import CalendarContainer from "../calendarContainer";
import { fetchOrgEvents } from "../../functions/events";
import { white } from "../../lib/colors";
import { fetchOrgSchedules } from "../../functions/schedules";
import IconButton from "../iconButton";
import Dropdown from "../dropDown";
import Badge from "../badge";

export default function ApplianceDetailsScreen({
  outlet,
  setCurrentOutlet,
  open,
  handleClose,
}) {
  const [events, setEvents] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [scheduleLoading, setScheduleLoading] = useState(false);

  const layoutProps = useOutletContext();
  const [viewDropdownValue, setViewDropdownValue] = useState("Active Schedules");
  const [viewDropdownOpen, setViewDropdownOpen] = useState(false);

  // Fetch events for the specific outlet
  useEffect(() => {
    if (layoutProps.orgData[0]?.uid && outlet?.id) {
      fetchOrgEvents(layoutProps.orgData[0].uid, setEvents, outlet.id);
    };
  }, [outlet]);

  useEffect(() => {
    if (layoutProps.orgData[0]?.uid && outlet?.id) {
      const fetchSchedules = async () => {
        setScheduleLoading(true);
        await fetchOrgSchedules(
          [layoutProps.orgData[0].uid],
          setSchedules,
          setScheduleLoading,
          outlet.id
        );
      };

      fetchSchedules();
    };
  }, [layoutProps.orgData, outlet]);

  const goBack = () => {
    setCurrentOutlet({});
    handleClose();
  };

  return (
    <Modal open={open} onClose={goBack}>
      <div style={{
        position: "absolute",
        top: "2.5%",
        left: "5%",
        width: '90vW',
        height: '95vH',
        overflowY: 'scroll',
        borderRadius: "24px",
        bgcolor: '#FAFAFA',
      }}>
        <div style={{
          backgroundColor: "#1F1F1F",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
          gap: "20px",
          overflowY: 'scroll',
        }}>
          {/* Header Section */}
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "1200px",
          }}>
            <Typography sx={{
              color: white,
              fontFamily: "IBM Plex Sans",
              fontSize: "32px",
              fontWeight: 400,
              lineHeight: "40px",
              marginTop: '10px',
              marginBottom: '20px'
            }}>
              {outlet.name} Details
            </Typography>
            <IconButton
              iconName='cancel-1'
              size={35}
              onClick={goBack} // Navigate back
            />
          </div>
          {/* </div> */}
          <div style={{
            display: "flex",
            gap: "40px",
            padding: "40px",
            borderRadius: "24px",
            maxWidth: "1250px",
            width: "100%",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: white,
            justifyContent: 'center',
          }}>
            {/* Left Section: ApplianceDetailsCard */}
            <ApplianceDetailsCard
              outlet={outlet}
              prodTypes={layoutProps.prodTypes}
              orgData={layoutProps.orgData}
              wattHours={layoutProps.wattHours}
              averageUsage={layoutProps.averageUsage}
              savings={layoutProps.savings}
              setCurrentOutlet={setCurrentOutlet}
            />
            {/* Right Section */}
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              width: "520px",
              //height: "500px",
              padding: "20px",
              borderRadius: "16px",
              border: "1px solid #E0E0E0",
              backgroundColor: "#F9F9F9",
            }}>
              {/* Daily Usage Chart */}
              <Box sx={{
                marginBottom: "10px",
                //paddingBottom: "40px",
                backgroundColor: "#FFFFFF",
                borderRadius: "16px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}>
                <DailyUsageChart
                  layoutProps={layoutProps}
                  outletID={outlet.id}
                />
              </Box>
              {/* Savings Display */}
              <Box sx={{
                padding: "16px",
                backgroundColor: "#FFFFFF",
                borderRadius: "16px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}>
                <SavingsDisplay
                  layoutProps={layoutProps}
                  outletID={outlet.id}
                />
              </Box>
            </Box>
          </div>
          {/* Schedule Section */}
          <Box sx={{
            // width: "100%",
            maxWidth: "1250px",
            padding: "40px",
            borderRadius: "16px",
            border: "1px solid #E0E0E0",
            backgroundColor: "#F9F9F9",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            marginBottom: '50px',
            marginTop: '-10px'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
              <div style={{ width: '33%' }}></div>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  fontSize: "18px",
                  textAlign: "center",
                  width: '33%',
                  alignSelf: 'center'
                }}
              >
                Schedules and Events
              </Typography>
              <div style={{
                width: '33%',
                display: 'flex',
                justifyContent: 'end',
              }}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {schedules?.filter(
                    (schedule) => schedule.status === "New"
                  ).length > 0 && viewDropdownValue === 'Active Schedules' &&
                    <div style={{position:'absolute', marginTop:'17px', marginLeft:'-2.5px'}}><Badge /></div>
                  }
                  <Dropdown
                    list={["Active Schedules", "Recommended Schedules"]}
                    value={viewDropdownValue}
                    onSelect={(item) => setViewDropdownValue(item)}
                    setIsDropdownOpen={setViewDropdownOpen}
                    isDropdownOpen={viewDropdownOpen}
                    label={"Schedule type"}
                  />
                </div>
              </div>
            </div>
            {viewDropdownValue === "Active Schedules" ? (
              <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                <CalendarContainer
                  layoutProps={layoutProps}
                  filteredEvents={events}
                  filteredSchedules={schedules}
                  filter="Accepted"
                />
              </div>
            ) : (
              <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                <CalendarContainer
                  layoutProps={layoutProps}
                  filteredEvents={events}
                  filteredSchedules={schedules?.filter(
                    (schedule) => schedule.status === "New"
                  )}
                  filter="New"
                />
              </div>
            )}
          </Box>
        </div>
      </div>
    </Modal>
  );
};
