import React, { useEffect, useState } from "react";
import OrganizationPicker from "../../components/orgPicker";
import OutletList from "../../components/outletList";
import { useOutletContext } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function ApplianceScreen() {
  // from layout
  const layoutProps = useOutletContext();

  // This is for filtering
  const [selectedApplianceType, setSelectedApplianceType] = useState("");
  // NOTE: Update this to filter existing WattHours from layout
  const handleApplianceTypeChange = (event) => {
    const selectedApplianceType = event.target.value;
    setSelectedApplianceType(selectedApplianceType);
  };

  // Render based on loading state
  return (
    <div style={{
      display: "flex",
      backgroundColor: '#1F1F1F',
      height: 'auto',
      minHeight: '100vh',
      minWidth: '1300px',
      justifyContent: 'center',
      marginTop: '86px'
    }}>
      <div style={{
        marginTop: '30px',
        display: "flex",
        flexDirection: "column",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: 'space-between'
        }}>
          <div
            className="headlineLarge"
            style={{
              width: "350px",
              height: "auto",
              display: "block",
              alignSelf: 'start',
              marginTop: '10px',
              color: '#FFFFFF',
            }}
          >
            Appliances
          </div>
          {(layoutProps.isOverviewAccount === 'true' || layoutProps.orgID == 'LZqI3R6MInUuwtgtROPK') &&
            <OrganizationPicker
              layoutProps={layoutProps}
              label={true}
            />
          }
        </div>
        <div style={{
          marginTop: '30px',
          display: "flex",
          flexDirection: "row",
          marginBottom: '30px'
        }}>
          <div style={{
            width: "1200px",
            display: "flex",
            borderRadius: '24px',
            minHeight: '693px',
            backgroundColor: '#FAFAFA',
            maxHeight: '800px',
            overflowY: 'hidden',
            marginRight: '16px',
            justifyContent: 'center'
          }}>
            {layoutProps.savingsLoading || layoutProps.outletsLoading || layoutProps.wattsLoading ? (
              <CircularProgress />)
              :
              <OutletList
                width={'1000px'}
                height={'750px'}
                layoutProps={layoutProps}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};