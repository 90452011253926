import React, { useState, useEffect } from "react";
import { Box, Typography, Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OnBar from "../onBar";
import { primary500 } from "../../lib/rebrandColors/colors";

/* eslint-disable react/jsx-key */
export default function WeeklyScheduleView({
  filteredSchedules,
  org,
  outlets,
  orgData,
  scheduleClick,
  filter,
}) {
  const hours = Array.from({ length: 24 }, (_, i) => i);

  return (
    <Box>
      {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => {
        const daySchedules = filteredSchedules.filter((schedule) =>
          schedule.repeat?.includes(day) || false
        );
        if (daySchedules.length === 0) return null;

        return (
          <DaySchedule
            key={day}
            day={day}
            daySchedules={daySchedules}
            hours={hours}
            outlets={outlets}
            org={org}
            orgData={orgData}
            scheduleClick={scheduleClick}
            filter={filter}
          />
        );
      })}
    </Box>
  );
};

function DaySchedule({
  day,
  daySchedules,
  hours,
  outlets,
  scheduleClick,
  filter,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const today = new Date().toLocaleString("en-US", { weekday: "short" });
    if (day === today) {
      setOpen(true);
    };
  }, [day]);

  const formatHour = (hour) => {
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour} ${period}`;
  };

  const uniqueHours = [
    ...new Set(
      daySchedules.flatMap((schedule) => [
        schedule.onHour,
        schedule.offHour || 24,
      ])
    ),
  ].sort((a, b) => a - b);

  const selectedOutlets = daySchedules.reduce((acc, schedule) => {
    // Only process schedules with status "Accepted"
    if (schedule.status === "Accepted" && filter == 'Accepted') {
      // For each schedule, filter the outlets based on outletsSelected
      const matchingOutlets = outlets.filter((outlet) =>
        schedule.outletsSelected.includes(outlet.id)
      );
      // Add the matching outlets to the accumulator
      return [...acc, ...matchingOutlets];
    } else if (schedule.status === "New" && filter == 'New') {
      // For each schedule, filter the outlets based on outletsSelected
      const matchingOutlets = outlets.filter((outlet) =>
        schedule.outletsSelected.includes(outlet.id)
      );
      // Group matching outlets by their productType
      matchingOutlets.forEach((outlet) => {
        if (!acc[outlet.productType]) {
          acc[outlet.productType] = []; // Initialize array for this productType
        }
        acc[outlet.productType].push(outlet); // Add outlet to the productType group
      });
    };
    return acc; // If the schedule does not have status "Accepted", skip it
  }, []);


  return (
    <Box sx={{ mb: 1 }}>
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <Typography sx={{
          fontSize: "0.9rem",
          fontFamily: "IBM Plex Mono",
          fontSize: "14px",
          color: primary500,
        }}>
          {day}
        </Typography>
        <Box sx={{
          flexGrow: 1,
          height: "1px",
          backgroundColor: primary500,
          mx: 1,
          alignSelf: "center",
        }} />
        <IconButton>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={open}>
        <Box sx={{ position: "relative", mb: 2 }}>
          <Box sx={{
            display: "flex",
            position: "absolute",
            top: "-16px",
            left: "150px",
          }}>
            {selectedOutlets?.length > 0 &&
              uniqueHours.map((hour) => (
                <Box
                  key={hour}
                  sx={{
                    position: "absolute",
                    left: `${hour * 40}px`,
                    width: "40px",
                    textAlign: "center",
                    color: primary500,
                    fontSize: "12px",
                    fontWeight: "bold",
                    transform: "translateY(-50%)",
                  }}
                >
                  {formatHour(hour)}
                </Box>
              ))}
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: 3.5,
            maxHeight: "250px",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}>
            {selectedOutlets?.length > 0 ?
              (selectedOutlets.filter((outlet, index, self) =>
                index === self.findIndex((o) => o.id === outlet.id)
              ).map((outlet, index) => {
                return (
                  <OutletRow
                    key={`${outlet.id}${index}`}
                    outlet={outlet}
                    schedules={daySchedules}
                    hours={hours}
                    uniqueHours={uniqueHours}
                    scheduleClick={scheduleClick}
                  />
                );
              }))
              : Object.entries(selectedOutlets).length > 0 ?
                Object.entries(selectedOutlets).map(([productType, outlets]) => {
                  return (
                    <ProductRow
                      key={productType}
                      productType={productType}
                      outlets={outlets}
                      schedules={daySchedules}
                      hours={hours}
                      uniqueHours={uniqueHours}
                      scheduleClick={scheduleClick}
                    />
                  );
                })
                :
                <Typography
                  sx={{
                    width: "300px",
                    fontFamily: "IBM Plex Mono",
                    textAlign: 'center'
                  }}
                >
                  No Active Schedules
                </Typography>
            }
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

function OutletRow({
  outlet,
  schedules,
  hours,
  uniqueHours,
  scheduleClick,
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Typography
        sx={{
          width: "150px",
          fontSize: "0.8rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {outlet.name}
      </Typography>
      <Box sx={{ display: "flex", position: "relative", width: "965px" }}>
        {hours.map((hour) => (
          <Box
            key={hour}
            sx={{
              width: "40px",
              height: "30px",
              borderRight: uniqueHours.includes(hour)
                ? "0px dotted #ccc"
                : "none",
              position: "relative",
              top: "-10px",
            }}
          />
        ))}

        {schedules
          .filter(
            (schedule) =>
              schedule.status === "Accepted" &&
              schedule.outletsSelected.includes(outlet.id)
          ).flatMap((schedule) => {
            if (schedule.onHour > schedule.offHour && schedule.offHour !== 0) {
              // Case when the schedule spans across two days
              return [
                {
                  id: `${schedule.id}-day1`, // Unique key for first part
                  start: schedule.onHour,
                  end: 24, // Until the end of the day
                  status: schedule.status,
                  schedule: schedule
                },
                {
                  id: `${schedule.id}-day2`, // Unique key for second part
                  start: 0, // Start of the next day
                  end: schedule.offHour,
                  status: schedule.status,
                  schedule: schedule
                },
              ];
            } else {
              // Regular case when the schedule is within the same day
              return [
                {
                  id: schedule.id,
                  start: schedule.onHour,
                  end: schedule.offHour === 0 ? 24 : schedule.offHour,
                  status: schedule.status,
                  schedule: schedule
                },
              ];
            }
          }).map(({ id, start, end, status, schedule }) => (
            <OnBar
              key={id}
              width={`${(end - start) * 40}px`}
              offset={`${start * 40}px`}
              status={status}
              schedule={schedule}
              scheduleClick={scheduleClick}
            />
          ))}
      </Box>
    </Box>
  );
};

function ProductRow({
  productType,
  outlets,
  schedules,
  hours,
  uniqueHours,
  scheduleClick
}) {
  const outletIds = outlets.map((outlet) => outlet.id);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Typography
        sx={{
          width: "150px",
          fontSize: "0.8rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {productType}
      </Typography>
      <Box sx={{ display: "flex", position: "relative", width: "960px" }}>
        {hours.map((hour) => (
          <Box
            key={hour}
            sx={{
              width: "40px",
              height: "30px",
              borderRight: uniqueHours.includes(hour)
                ? "0px dotted #ccc"
                : "none",
              position: "relative",
              top: "-10px",
            }}
          />
        ))}

        {schedules.filter(
          (schedule) =>
            schedule.status === "New" &&
            schedule.outletsSelected.some((id) => outletIds.includes(id))
        ).flatMap((schedule) => {
          if (schedule.onHour > schedule.offHour && schedule.offHour !== 0) {
            // Case when the schedule spans across two days
            return [
              {
                id: `${schedule.id}-day1`, // Unique key for first part
                start: schedule.onHour,
                end: 24, // Until the end of the day
                status: schedule.status,
                schedule: schedule
              },
              {
                id: `${schedule.id}-day2`, // Unique key for second part
                start: 0, // Start of the next day
                end: schedule.offHour,
                status: schedule.status,
                schedule: schedule
              },
            ];
          } else {
            // Regular case when the schedule is within the same day
            return [
              {
                id: schedule.id,
                start: schedule.onHour,
                end: schedule.offHour === 0 ? 24 : schedule.offHour,
                status: schedule.status,
                schedule: schedule
              },
            ];
          };
        }).map(({ id, start, end, status, schedule }) => (
          <OnBar
            key={id}
            width={`${(end - start) * 40}px`}
            offset={`${start * 40}px`}
            status={status}
            schedule={schedule}
            scheduleClick={scheduleClick}
          />
        ))}
      </Box>
    </Box>
  );
};