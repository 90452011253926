import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, clearAuthError } from "../../actions/auth/login";
import { useNavigate } from "react-router";
import Login from "./loginPresentational";
import { getMultiFactorResolver } from "@firebase/auth";
import { auth } from "../../firebase";

export default function LoginScreen() {
    const error = useSelector((state) => state.auth.error);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
        severity: "",
    });
    const handleClose = () => {
        setOpen(false);
        dispatch(clearAuthError());
    };

    function handleLogin(email, password, sign_in_method) {
        setLoading(true);

        dispatch(login(email, password, sign_in_method, setAlert))
            .then(() => {
                setLoading(false);
                //console.log("User signed in successfully.");
                console.log("Attempting to sign in");
            }).catch((error) => {
                setLoading(false);
                console.log("Error");

                if (error.code === "auth/multi-factor-auth-required") {
                    window.localStorage.setItem("approvedSignIn", JSON.stringify(true));
                    const resolver = getMultiFactorResolver(auth, error)
                    window.resolver = resolver;
                    navigate("/login/otp");
                } else {
                    console.error("Error during login:", error);
                };
            });
    };
    function handleError(errorMessage) {
        const startIndex = String(errorMessage).indexOf("auth");
        const endIndex = String(errorMessage).indexOf("]");
        const err = String(errorMessage).substring(startIndex + 5, endIndex);
        let message = err;
        if (
            err === "invalid-email"
            || err === "user-not-found"
            || err === "invalid-password"
            || err === "wrong-password") {
            message = "Invalid email or password, please try again";
            setOpen(true);
        } else {
            message = "Sign in error";
        };
        setOpen(true);
    };
    // error handler
    useEffect(() => {
        if (error) {
            setLoading(false);
            handleError(error);
            setOpen(true);
        } else {
            setOpen(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <Login
            onLogin={handleLogin}
            loading={loading}
            setLoading={setLoading}
            navigate={navigate}
            open={open}
            handleClose={handleClose}
            alert={alert}
            setAlert={setAlert}
        />
    );
};
